import React, { useRef } from 'react'
import "./SummaryModal.css";
import biocare_logo from "../../assets/images/biocare-logo.png";
import logo from "../../assets/images/Logo.svg";
import { Linechart } from "../subComponents/recharts/Linechart.jsx";
import { Barchart } from "../subComponents/recharts/Barchart.jsx";
import { StackedBarchart } from "../subComponents/recharts/StackedBarchart.jsx";
import moment from 'moment';
import { userInfo } from 'os';
import html2pdf from 'html2pdf.js';
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import { Tabular } from '../subComponents/tabular/Tabular';
import { Tooltip } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';


function SummaryModal() {
    const currentDate = moment().format('D MMM YYYY');
    const user = JSON.parse(localStorage.getItem("user"));
    const history = useHistory();

    const pdfRef = useRef(null);

    const downloadPdf = () => {
        toast.success('File will be Downloaded Shortly!', {
            position: toast.POSITION.TOP_RIGHT
        });
        const opt = {
            margin: 0.5,
            filename: 'report.pdf',
            image: { type: 'jpeg', quality: 0.98 },
            html2canvas: { scale: 2 },
            jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' }
        };

        html2pdf()
            .from(pdfRef.current)
            .set(opt)
            .save();
    };

    return (
        <div className='summary-main'>
            <div className='top-header'>
                <p className='summary-title'>Preview Summary</p>
                <Tooltip title="Download pdf" arrow>
                    <FileDownloadOutlinedIcon style={{ cursor: "pointer", color: "#2F54EB", marginTop: "5px" }} onClick={downloadPdf} />
                </Tooltip>
                <Tooltip title="Close Summary" arrow>
                    <CloseIcon style={{ marginLeft: "auto", cursor: "pointer" }} onClick={history.goBack} />
                </Tooltip>
            </div>
            <div className='mt-20'></div>
            <div className='pdf-form' id="pdf-form" ref={pdfRef}>
                <div className='logo-container'>
                    <div className='logo-wraper'>
                        <img className='fieldforce-logo' src={logo} ></img>
                        <img
                            className="biocare-logo"
                            src={biocare_logo}
                        />
                    </div>
                    <hr className='line'></hr>
                </div>
                <div className='title1'>
                    <p>Call Average Summary</p>
                </div>
                <div className='chart1'>
                    <Linechart />
                </div>
                <div className='chart2'>
                    <Barchart />
                </div>
                <div className='chart3'>
                    <StackedBarchart />
                </div>

                <div className='table1' >
                    <Tabular />
                </div>

                <div className='form-footer'>
                    <p className='text-wraper'> Generated by {user.result[0].user.firstName} {user.result[0].user.lastName} </p>
                    <p className='date-wraper'>{currentDate}</p>

                </div>

            </div>
        </div>
    )
}

export default SummaryModal