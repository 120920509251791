import {
    DOCTOR_RANK_SUCCESS,
    DOCTOR_RANK_PENDING,
    DOCTOR_RANK_FAIL,
    ADD_DOCTOR_RANK_SUCCESS,
    ADD_DOCTOR_RANK_PENDING,
    ADD_DOCTOR_RANK_FAIL,
    UPDATE_DOCTOR_RANK_SUCCESS,
    UPDATE_DOCTOR_RANK_PENDING,
    UPDATE_DOCTOR_RANK_FAIL,
} from "../../actions/types";

const initialState = { 
    ranks: [],
    addRanks :'',
    updateRanks : ''
    };

const doctor_rank_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
            case DOCTOR_RANK_SUCCESS:
            return {
                ...state,
                ranks: payload.ranks,
            };
        case DOCTOR_RANK_PENDING:
            return {
                ...state,
                ranks: payload.ranks,
            };
        case DOCTOR_RANK_FAIL:
            return {
                ...state,
                ranks: []
            };
            case ADD_DOCTOR_RANK_SUCCESS:
                return {
                    ...state,
                    addRanks: payload.addRanks,
                };
            case ADD_DOCTOR_RANK_PENDING:
                return {
                    ...state,
                    addRanks: payload.addRanks,
                };
            case ADD_DOCTOR_RANK_FAIL:
                return {
                    ...state,
                    addRanks: false
                };
            case UPDATE_DOCTOR_RANK_SUCCESS:
                    return {
                        ...state,
                        updateRanks: payload.updateRanks,
                    };
            case UPDATE_DOCTOR_RANK_PENDING:
                    return {
                        ...state,
                        updateRanks: payload.updateRanks,
                    };
            case UPDATE_DOCTOR_RANK_FAIL:
                    return {
                        ...state,
                        updateRanks: false
                    };
        default:
            return state;
    }
}
export default doctor_rank_reducer