import {
    TASK_PRIORITY_SUCCESS,
    TASK_PRIORITY_FAIL,
    TASK_PRIORITY_PENDING,
    ADD_TASK_PRIORITY_SUCCESS,
    ADD_TASK_PRIORITY_FAIL,
    ADD_TASK_PRIORITY_PENDING,
    UPDATE_TASK_PRIORITY_SUCCESS,
    UPDATE_TASK_PRIORITY_FAIL,
    UPDATE_TASK_PRIORITY_PENDING
} from "../../actions/types";

const initialState = { 
    taskPriorities: [],
    addTaskPriorities :'',
    updateTaskPriorities : ''
    };

const task_priorities_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case TASK_PRIORITY_SUCCESS:
            return {
                ...state,
                taskPriorities: payload.taskPriorities,
            };
        case TASK_PRIORITY_PENDING:
            return {
                ...state,
                taskPriorities: payload.taskPriorities,
            };
        case TASK_PRIORITY_FAIL:
            return {
                ...state,
                taskPriorities: []
            };
        case ADD_TASK_PRIORITY_SUCCESS:
            return {
                ...state,
                addTaskPriorities: payload.addTaskPriorities,
            };
        case ADD_TASK_PRIORITY_PENDING:
            return {
                ...state,
                addTaskPriorities: payload.addTaskPriorities,
            };
        case ADD_TASK_PRIORITY_FAIL:
            return {
                ...state,
                addTaskPriorities: false
            };
        case UPDATE_TASK_PRIORITY_SUCCESS:
                return {
                    ...state,
                    updateTaskPriorities: payload.updateTaskPriorities,
                };
        case UPDATE_TASK_PRIORITY_PENDING:
                return {
                    ...state,
                    updateTaskPriorities: payload.updateTaskPriorities,
                };
        case UPDATE_TASK_PRIORITY_FAIL:
                return {
                    ...state,
                    updateTaskPriorities: false
                };
        default:
            return state;
    }
}
export default task_priorities_reducer