import {
  UNIT_TYPE_SUCCESS,
  UNIT_TYPE_FAIL,
  SET_MESSAGE,
  UNIT_TYPE_PENDING,
  ADD_UNIT_TYPE_SUCCESS,
  ADD_UNIT_TYPE_FAIL,
  ADD_UNIT_TYPE_PENDING,
  UPDATE_UNIT_TYPE_SUCCESS,
  UPDATE_UNIT_TYPE_FAIL,
  UPDATE_UNIT_TYPE_PENDING,
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";

export const getUnitTypes = (body) => async (dispatch) => {
  dispatch({
    type: UNIT_TYPE_PENDING,
    payload: { unitTypes: "isLoading" },
  });
  return await UserService.getUnitTypes(body)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: UNIT_TYPE_SUCCESS,
              payload: { unitTypes: data.data.result[0] },
            })
          : dispatch({
              type: UNIT_TYPE_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UNIT_TYPE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

//add unit type
export const addUnitType = (body) => async (dispatch) => {
  dispatch({
    type: ADD_UNIT_TYPE_PENDING,
    payload: { addUnitTypes: "isLoading" },
  });
  return await UserService.addUnitType(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Unit Type added Successfully !", { autoClose: 2000 })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: ADD_UNIT_TYPE_SUCCESS,
              payload: { addUnitTypes: data.data.status },
            })
          : dispatch({
              type: ADD_UNIT_TYPE_FAIL,
              payload: { addUnitTypes: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_UNIT_TYPE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};
// Update unitTypes
export const updateUnitType = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_UNIT_TYPE_PENDING,
    payload: { updateUnitType: "isLoading" },
  });
  return await UserService.updateUnitType(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Unit Type updated Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: UPDATE_UNIT_TYPE_SUCCESS,
              payload: { updateUnitType: data.data.status },
            })
          : dispatch({
              type: UPDATE_UNIT_TYPE_FAIL,
              payload: { updateUnitType: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_UNIT_TYPE_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const nullUpdateUnitType = () => (dispatch) => {
  return dispatch({
    type: UPDATE_UNIT_TYPE_PENDING,
    payload: { updateUnitType: "" },
  });
};
