import {
    CITY_SUCCESS,
    CITY_FAIL,
    CITY_PENDING,
    ADD_CITY_SUCCESS,
    ADD_CITY_FAIL,
    ADD_CITY_PENDING,
    UPDATE_CITY_SUCCESS,
    UPDATE_CITY_FAIL,
    UPDATE_CITY_PENDING,
} from "../../actions/types";

const initialState = { 
    cities: [],
    addCity : '',
    updateCity : ''
    };

const cities_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case CITY_SUCCESS:
            return {
                ...state,
                cities: payload.cities,
            };
        case CITY_PENDING:
            return {
                ...state,
                cities: payload.cities,
            };
        case CITY_FAIL:
            return {
                ...state,
                cities: []
            };
        case ADD_CITY_SUCCESS:
            return {
                ...state,
                addCity: payload.addCity,
            };
        case ADD_CITY_PENDING:
            return {
                ...state,
                addCity: payload.addCity,
            };
        case ADD_CITY_FAIL:
            return {
                ...state,
                addCity: false
            };
        case UPDATE_CITY_SUCCESS:
            return {
                ...state,
                updateCity: payload.updateCity,
            };
        case UPDATE_CITY_PENDING:
            return {
                ...state,
                updateCity: payload.updateCity,
            };
        case UPDATE_CITY_FAIL:
            return {
                ...state,
                updateCity: false
            };
        default:
            return state;
    }
}
export default cities_reducer