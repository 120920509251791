import React from 'react'
import { useHistory } from 'react-router-dom'
import './InnerHeader.css'
import Search from '../subComponents/search/Search'
import ServerSideSearch from '../subComponents/search//ServerSideSearch'
import Button from '@mui/material/Button'

const InnerHeader = (props) => {
  const history = useHistory()
  return (
    <>
      <div className="row">
        <div className="col-md-6">
          <div className="d-flex flex-row align-items-center">
            {/* <h2>
                            <i onClick={history.goBack} className="fas fa-arrow-left" aria-hidden="true" style={{ cursor: "pointer" }} />
                        </h2> */}
            <div className="d-flex flex-column ml-3">
              <h2 className=""> {props.title}</h2>
              <p>{props.description}</p>
            </div>
          </div>
        </div>
        <div className="col-md-6">
          <div
            className="d-flex float-right align-items-center"
            style={{ height: '100%' }}
          >
            <div style={{ height: '35px' }}>
              {props.serverSideSearch == true ? (
                <ServerSideSearch
                  handleSearch={props.handleSearch}
                  value={props.value}
                  placeholder={props.searchPlaceholder}
                />
              ) : (
                <Search
                  handleSearch={props.handleSearch}
                  value={props.value}
                  placeholder={props.searchPlaceholder}
                />
              )}

              {props.permission && (
                <Button
                  className="btn btn-primary float-right"
                  style={{
                    textTransform: 'capitalize',
                    boxShadow: '0px !important',
                  }}
                  variant="contained"
                  onClick={() => props.onClick()}
                >
                  {props.buttonTitle}
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default InnerHeader
