import {
  CALL_AVERAGE_EMP_SUCCESS,
  CALL_AVERAGE_EMP_FAIL,
  CALL_AVERAGE_EMP_PENDING,
} from "../../actions/types";

const initialState = {
  callAvgEmp: [],
};

const call_avg_emp_reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case CALL_AVERAGE_EMP_SUCCESS:
      return {
        ...state,
        callAvgEmp: payload.callAvgEmp,
      };
    case CALL_AVERAGE_EMP_PENDING:
      return {
        ...state,
        callAvgEmp: payload.callAvgEmp,
      };
    case CALL_AVERAGE_EMP_FAIL:
      return {
        ...state,
        callAvgEmp: [],
      };
    default:
      return state;
  }
};
export default call_avg_emp_reducer;
