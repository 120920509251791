import React from 'react';
import PropTypes from 'prop-types';
import { Button, CircularProgress } from '@mui/material';
import Loader from '../Loader';

export const SaveButton = ({ onClick, isLoading, text }) => {
  return (
    <Button
      onClick={onClick}
      disabled={isLoading}
      style={{
        position: "relative",
        height: "40px",
        width: "114px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "6px 14px",
        gap: "10px",
        borderRadius: "2px",
        border: "1px solid #D9D9D9",
        background: "#002D72",
        color: "#FFFFFF",
        boxShadow: "2px #00000004",
        textTransform: "none",
        cursor: "pointer",
        transition: "background-color 0.3s ease"
      }}
      onMouseOver={(e) => e.currentTarget.style.backgroundColor = "#004BB4"}
      onMouseOut={(e) => e.currentTarget.style.backgroundColor = "#002D72"}
    >
      {isLoading ? (
        <div style={{ display: "inline-block", marginTop: "8px" }}>
          <CircularProgress size={24} style={{ color: '#FFFFFF' }} />
        </div>
      ) : (
        text
      )}
    </Button>
  );
};

SaveButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  text: PropTypes.string.isRequired
};

export default SaveButton;