import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getCountries } from '../../actions/userActions/countryAction'
import { addCities, updateCities } from '../../actions/userActions/cityAction'
import PreLoader from '../preLoader/PreLoader'
import Loader from '../subComponents/Loader'
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import { SaveButton } from "../subComponents/buttons/SaveButton";
import { CancelButton } from "../subComponents/buttons/CancelButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { CircularProgress } from '@mui/material'

function AddCities() {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = { name: "", shortName: "", countryId: "" };
    const [loading, setLoading] = useState(true); // Loading state
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
              await dispatch(getCountries());
              if (location.city) {
                setFormValues({
                    ...formValues,
                    name: location.city.row.name,
                    shortName: location.city.row.shortName,
                    countryId: location.city.row.countryId,
                    id: location.id
                });
            }
            } catch (error) {
              console.error("Error fetching data:", error);
            } finally {
              setLoading(false); // Set loading to false once data is fetched
            }
          };
      
          fetchData();
    }, [dispatch, location.city]);

    const addSubmitHandle = async () => {
        const errors = validate(formValues);
        setFormErrors(errors);

        if (Object.keys(errors).length === 0) {
            if (location.city) {
                dispatch(updateCities(formValues));
            } else {
                dispatch(addCities(formValues));
            }
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
    }

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Name is required!"
        }
        if (!values.shortName) {
            errors.shortName = "Short Name is required!"
        }
        if (values.countryId === '') {
            errors.countryId = "Country is required!"
        }
        return errors;
    }
    const getOptions = (data, name) => {
        const getData =
            data && data !== 'isLoading'
                ? data.map((row, i) => ({
                    value: row.id,
                    label: row.name
                        ? row.name
                        : row.firstName && row.lastName
                            ? `${row.firstName} ${row.lastName}`
                            : row.address,
                    name: name,
                }))
                : []
        return getData
    }
    const countries = useSelector(state => state.countries_reducer.countries);
    const addCity = useSelector(state => state.cities_reducer.addCity);
    const updateCity = useSelector(state => state.cities_reducer.updateCity);
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));
    
    const handleGoBack = () => {
        history.goBack();
    };
    if (loading) {
        return (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
            <CircularProgress size={60} style={{ color: '#002D72' }} />
          </div>
        );
      }
    return (
        <>
            {addCity && addCity != "isLoading" && <Redirect to="/admin/cities" />}
            {updateCity && updateCity != "isLoading" && <Redirect to="/admin/cities" />}
            {!location.city && <Redirect to="/admin/AddCities" />} 
             {!permissions.includes("CanAddCity") && <Redirect to="/admin" />}
            {!permissions.includes("CanUpdateCity") && <Redirect to="/admin" />} 
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="d-inine-block float-left">
                            <i
                                onClick={history.goBack}
                                className="fas fa-arrow-left"
                                aria-hidden="true"
                                style={{ cursor: "pointer" }}
                            />{" "}
                            {location.city ? "Update City" : "Add City"}
                        </h2>
                    </div>
                    <hr
                        style={{
                            marginLeft: "14px",
                            backgroundColor: "rgba(0, 0, 0, 0.12)",
                            height: "1.3px",
                            marginTop: "15px",
                            width: "97%",
                        }}
                    />
                    <div className="col-md-6"></div>
                </div>
                <div className="row">
                        <div className='col-6 mt-5'>
                            <TextField
                                style={{ width: "100%", fontSize: "46px" }}
                                className="first_textfield"
                                id="outlined-controlled"
                                label="Name *"
                                name="name"
                                value={formValues.name}
                                onChange={handleChange}
                            />
                            <div style={{ color: "red" }}>{formErrors.name}</div>
                        </div>

                        <div className="col-6 mt-5">
                            <TextField
                                style={{ width: "100%", fontSize: "46px" }}
                                className="first_textfield"
                                id="outlined-controlled"
                                label="Short Name *"
                                name="shortName"
                                value={formValues.shortName}
                                onChange={handleChange}
                            />
                            <div style={{ color: "red" }}>{formErrors.shortName}</div>
                        </div>

                        <div className='col mt-5'>
                            <FormControl fullWidth>
                                <InputLabel id="demo-simple-select-label">Country *</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={formValues.countryId}
                                    onChange={handleChange}
                                    label="Country *"
                                    name='countryId'
                                    MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
                                >
                                    {countries &&
                                        countries !== "isLoading" &&
                                        countries.length !== 0 &&
                                        getOptions(countries, 'countryId')
                                            .map((option) => (
                                                <MenuItem key={option.value} value={option.value}>
                                                    {option.label}
                                                </MenuItem>
                                            ))}
                                </Select>
                                <div style={{ color: 'red' }}>{formErrors.countryId}</div>
                            </FormControl>
                        </div>

                        <div className="col-12 mt-10" style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
                            <CancelButton
                                onClick={handleGoBack}
                            />
                            <SaveButton
                                onClick={addSubmitHandle}
                                isLoading={addCity === "isLoading" || updateCity === "isLoading"}
                                text={location.city ? "Update" : "Save"}
                            />
                    </div>

                </div>
            </div>
        </>
    )
}

export default AddCities
