import {
    COUNTRY_SUCCESS,
    COUNTRY_FAIL,
    COUNTRY_PENDING
} from "../../actions/types";

const initialState = { 
    countries: [],
    };

const countries_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case COUNTRY_SUCCESS:
            return {
                ...state,
                countries: payload.countries,
            };
        case COUNTRY_PENDING:
            return {
                ...state,
                countries: payload.countries,
            };
        case COUNTRY_FAIL:
            return {
                ...state,
                countries: []
            };
        default:
            return state;
    }
}
export default countries_reducer