import {
  OFFICE_LOCATION_SUCCESS,
  OFFICE_LOCATION_FAIL,
  SET_MESSAGE,
  OFFICE_LOCATION_PENDING,
  ADD_OFFICE_LOCATION_SUCCESS,
  ADD_OFFICE_LOCATION_FAIL,
  ADD_OFFICE_LOCATION_PENDING,
  UPDATE_OFFICE_LOCATION_SUCCESS,
  UPDATE_OFFICE_LOCATION_FAIL,
  UPDATE_OFFICE_LOCATION_PENDING,
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";

export const getOfficeLocations = (body) => async (dispatch) => {
  dispatch({
    type: OFFICE_LOCATION_PENDING,
    payload: { officeLocations: "isLoading" },
  });
  return await UserService.getOfficeLocations(body)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: OFFICE_LOCATION_SUCCESS,
              payload: { officeLocations: data.data.result },
            })
          : dispatch({
              type: OFFICE_LOCATION_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: OFFICE_LOCATION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      return Promise.reject();
    });
};

export const addOfficeLocation = (body) => async (dispatch) => {
  dispatch({
    type: ADD_OFFICE_LOCATION_PENDING,
    payload: { addOfficeLocation: "isLoading" },
  });
  return await UserService.addOfficeLocation(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("Office location added Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: ADD_OFFICE_LOCATION_SUCCESS,
              payload: { addOfficeLocation: data.data.status },
            })
          : dispatch({
              type: ADD_OFFICE_LOCATION_FAIL,
              payload: { addOfficeLocation: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_OFFICE_LOCATION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

export const updateOfficeLocation = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_OFFICE_LOCATION_PENDING,
    payload: { updateOfficeLocation: "isLoading" },
  });
  return await UserService.updateOfficeLocation(body)
    .then(
      (data) => {
        console.log(data.data);
        data.data.status
          ? toast.success("Office location updated Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: UPDATE_OFFICE_LOCATION_SUCCESS,
              payload: { updateOfficeLocation: data.data.status },
            })
          : dispatch({
              type: UPDATE_OFFICE_LOCATION_FAIL,
              payload: { updateOfficeLocation: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_OFFICE_LOCATION_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};
