import {
  OFFICE_LOCATION_SUCCESS,
  OFFICE_LOCATION_FAIL,
  OFFICE_LOCATION_PENDING,
  ADD_OFFICE_LOCATION_SUCCESS,
  ADD_OFFICE_LOCATION_FAIL,
  ADD_OFFICE_LOCATION_PENDING,
  UPDATE_OFFICE_LOCATION_SUCCESS,
  UPDATE_OFFICE_LOCATION_FAIL,
  UPDATE_OFFICE_LOCATION_PENDING,
} from "../../actions/types";

const initialState = {
  officeLocations: [],
  addOfficeLocation: "",
  updateOfficeLocation: "",
};

const officeLocations_reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case OFFICE_LOCATION_SUCCESS:
      return {
        ...state,
        officeLocations: payload.officeLocations,
      };
    case OFFICE_LOCATION_PENDING:
      return {
        ...state,
        officeLocations: payload.officeLocations,
      };
    case OFFICE_LOCATION_FAIL:
      return {
        ...state,
        officeLocations: [],
      };
    case ADD_OFFICE_LOCATION_SUCCESS:
      return {
        ...state,
        addOfficeLocation: payload.addOfficeLocation,
      };
    case ADD_OFFICE_LOCATION_PENDING:
      return {
        ...state,
        addOfficeLocation: payload.addOfficeLocation,
      };
    case ADD_OFFICE_LOCATION_FAIL:
      return {
        ...state,
        addOfficeLocation: false,
      };
    case UPDATE_OFFICE_LOCATION_SUCCESS:
      return {
        ...state,
        updateOfficeLocation: payload.updateOfficeLocation,
      };
    case UPDATE_OFFICE_LOCATION_PENDING:
      return {
        ...state,
        updateOfficeLocation: payload.updateOfficeLocation,
      };
    case UPDATE_OFFICE_LOCATION_FAIL:
      return {
        ...state,
        updateOfficeLocation: false,
      };
    default:
      return state;
  }
};
export default officeLocations_reducer;
