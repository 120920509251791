import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getTaskStatuses } from "../../actions/userActions/taskStatusAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import Pagination from '../paginatioin/pagination';

function TaskStatuses() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(10)
  useEffect(() => {
    dispatch(getTaskStatuses());
  }, []);
  const taskStatuses = useSelector(
    (state) => state.task_statuses_reducer.taskStatuses
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [query, setQuery] = useState("");
  const filter_taskStatuses =
    taskStatuses !== "isLoading"
      ? taskStatuses.filter((taskStatus) => {
        return taskStatus.name.toLowerCase().includes(query.toLowerCase());
      })
      : [];
  const handleAddTaskStatusClick = () => {
    history.push('/admin/addTaskStatuses');
  };
  // Pagination logic
  const indexOfLastTaskStatus = currentPage * perPage;
  const indexOfFirstTaskStatus = indexOfLastTaskStatus - perPage;
  const currentTaskStatus = filter_taskStatuses.slice(indexOfFirstTaskStatus, indexOfLastTaskStatus);
  const totalPages = Math.ceil(filter_taskStatuses.length / perPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 md-5">
            <h2 className="d-inline-block">Task Status</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <Search
              setText={setQuery}
              text={query}
              placeholder="Search Status"
            />
            {permissions.includes("CanAddTaskStatus") && (
              <AddButton onClick={handleAddTaskStatusClick} text="+ Add Task Status" />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Status</b>{" "}
                  </td>
                  {/* <td>
                    {" "}
                    <b className="heading_role">Action</b>{" "}
                  </td> */}
                </tr>
              </thead>
              <tbody className="text-left">
                {taskStatuses === "isLoading" && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td
                      colSpan={3}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {taskStatuses !== "isLoading" &&
                  currentTaskStatus.length === 0 && (
                    <tr className="text-center" style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}>
                      <td colSpan={3}>No data found</td>
                    </tr>
                  )}

                {taskStatuses !== "isLoading" &&
                  currentTaskStatus
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((row, i) => (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}
                      >
                        <td>{row.name}</td>
                        <td>{row.description}</td>
                        <td>
                          <span
                            style={{
                              color: row.active
                                ? "#2196F3"
                                : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                              borderRadius: "100px",
                              border: `1px solid ${row.active
                                ? "var(--primary-main, #2196F3)"
                                : "var(--chip-defaultEnabledBorder, #BDBDBD)"
                                }`,
                              padding: "3px 10px",
                            }}
                          >
                            {row.active !== undefined ? (row.active ? "Active" : "In-active") : "Active"}
                          </span>

                        </td>

                        {/* <td>
                        {permissions.includes("CanUpdateTaskStatus") && (
                          <Link
                          to={{pathname: "/admin/UpdateTaskStatuses", taskStatuses: {row} ,id : row.id }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                            >
                              <path
                                d="M14.0588 9.02L14.9788 9.94L5.91878 19H4.99878V18.08L14.0588 9.02ZM17.6588 3C17.4088 3 17.1488 3.1 16.9588 3.29L15.1288 5.12L18.8788 8.87L20.7088 7.04C21.0988 6.65 21.0988 6.02 20.7088 5.63L18.3688 3.29C18.1688 3.09 17.9188 3 17.6588 3ZM14.0588 6.19L2.99878 17.25V21H6.74878L17.8088 9.94L14.0588 6.19Z"
                                fill="#505157"
                              />
                            </svg>
                          </Link>
                        )}
                      </td> */}
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='task-pagination-wrapper'>
          <Pagination
            total={totalPages}
            current={currentPage}
            pageSize={1}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default TaskStatuses;
