import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  getPracticeLocationTypes,
  addPracticeLocation,
  updatePracticeLocations,
} from '../../actions/userActions/practiceLocationAction'
import { getCities } from '../../actions/userActions/cityAction'
import { Redirect } from 'react-router-dom'
import { useLocation, useHistory } from 'react-router-dom'
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import CancelButton from '../subComponents/buttons/CancelButton'
import SaveButton from '../subComponents/buttons/SaveButton'
import { CircularProgress } from '@mui/material'

function AddPracticeLocations() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    name: '',
    type: '',
    address: '',
    city: '',
  }
  const [loading, setLoading] = useState(true); // Loading state
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })
  const cityBody = {
    filter: cityFilters,
    pageNumber: -1,
    pageSize: 0,
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(getCities(cityBody))
        .then(() => {
          dispatch(getPracticeLocationTypes(-1));
        })
        .catch(error => {
          console.error("Error fetching data:", error);
        });
        
        if (location.practiceLocation) {
          setFormValues({
            ...formValues,
            ['name']: location.practiceLocation.row.name,
            ['type']: location.practiceLocation.row.type,
            ['address']: location.practiceLocation.row.address,
            ['city']: location.practiceLocation.row.city,
            ['id']: location.id,
          });
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false); // Set loading to false once data is fetched
      }
    };

    fetchData();
    
    
  }, [location]);

  const addSubmitHandle = (e) => {
    const errors = validate(formValues);
    setFormErrors(errors);

    if (Object.keys(errors).length === 0) {
      if (location.practiceLocation) {
        dispatch(updatePracticeLocations(formValues));
      } else {
        dispatch(addPracticeLocation(formValues));
      }
    }
  };


  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Name is required!'
    }
    if (!values.address) {
      errors.address = 'Address is required!'
    }
    if (values.city === '') {
      errors.city = 'City is required!'
    }
    if (values.type === '') {
      errors.type = 'Type is required!'
    }
    return errors
  }

  const allCities = useSelector((state) => state.cities_reducer.cities)
  const practiceLocationType = useSelector(
    (state) => state.practiceLocations_reducer.practiceLocationTypes,
  )
  const addPracticeLocations = useSelector(
    (state) => state.practiceLocations_reducer.addPracticeLocation,
  )
  const updatePracticeLocation = useSelector(
    (state) => state.practiceLocations_reducer.updatePracticeLocation,
  )
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const handleGoBack = () => {
    history.goBack();
  };
  if (loading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '75vh' }}>
        <CircularProgress size={60} style={{ color: '#002D72' }} />
      </div>
    );
  }
  return (
    <>
      {addPracticeLocations &&
        addPracticeLocations != 'isLoading' &&
         <Redirect to="/admin/practiceLocations" />}
         {updatePracticeLocation && updatePracticeLocation != "isLoading" && <Redirect to="/admin/practiceLocations" />}
      {!permissions.includes('CanAddOfficeLocation') && (
        <Redirect to="/admin" />
      )}
      {!permissions.includes('CanUpdateOfficeLocation') && (
        <Redirect to="/admin" />
      )}
      {!location.practiceLocation && (
        <Redirect to={'/admin/AddPracticeLocations'} />
      )}
      <div>
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: 'pointer' }}
              />{' '}
              {location.practiceLocation
                ? 'Update Practice Location'
                : 'Add Practice Location'}
            </h2>
          </div>
          <hr
            style={{
              marginLeft: "14px",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
              height: "1.3px",
              marginTop: "15px",
              width: "97%",
            }}
          />
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Name *"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
            <div style={{ color: 'red' }}>{formErrors.name}</div>
          </div>
          <div className="col-md-6">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">Location Type *</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={formValues.type}
                onChange={handleChange}
                label="Location Type *"
                name='type'
                MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
              >
                 {practiceLocationType === "isLoading" && (
                    <MenuItem disabled>
                      Loading...
                    </MenuItem>
                  )}
                  {practiceLocationType !== "isLoading" && practiceLocationType.length === 0 && (
                    <MenuItem disabled>
                      No data
                    </MenuItem>
                  )}
                  {practiceLocationType !== "isLoading" && practiceLocationType.length !== 0 && practiceLocationType
                    // .filter(locationType => locationType.active)
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(locationType => (
                      <MenuItem key={locationType.id} value={locationType.id}>
                        {locationType.name}
                      </MenuItem>
                    ))
                  }
              </Select>
              <div style={{ color: 'red' }}>{formErrors.type}</div>
            </FormControl>
          </div>
          <div className="col-md-3 mt-5">
          <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">City *</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={formValues.city}
                  onChange={handleChange}
                  label="City *"
                  name='city'
                  MenuProps={{ PaperProps: { style: { maxHeight: 210 } } }}
                >
                  {allCities === "isLoading" && (
                    <MenuItem disabled>
                      Loading...
                    </MenuItem>
                  )}
                  {allCities !== "isLoading" && allCities.length === 0 && (
                    <MenuItem disabled>
                      No data
                    </MenuItem>
                  )}
                  {allCities !== "isLoading" && allCities.length !== 0 && allCities[0].cities
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map(city => (
                      <MenuItem key={city.id} value={city.id}>
                        {city.name}
                      </MenuItem>
                    ))
                  }
                </Select>
                <div style={{ color: 'red' }}>{formErrors.city}</div>
              </FormControl>
          </div>
          <div className="col-md-9 mt-5">
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Address *"
              name="address"
              value={formValues.address}
              onChange={handleChange}
            />
            <div style={{ color: 'red' }}>{formErrors.address}</div>
          </div>
          <div className="col-lg-12 mt-10" style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
            <CancelButton
              onClick={handleGoBack}
            />
            <SaveButton
              onClick={addSubmitHandle}
              isLoading={addPracticeLocations === "isLoading" || updatePracticeLocations === "isLoading"}
              text={location.practiceLocationType ? "Update" : "Save"}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AddPracticeLocations
