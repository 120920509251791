import React from 'react'
import './Search.css'
import TextField from '@mui/material/TextField';

const Search = (props) => {
  return (
    <TextField
      sx={{
        width: 220,
        height: 40,
        '& .MuiOutlinedInput-root': {
          borderRadius: '2px',
        },
      }}
      type="text"
      placeholder="Search"
      onChange={(e) => props.setText(e.target.value)}
      value={props.text}
      variant="outlined"
      InputProps={{
        style: {
          height: '100%',
        },
      }}
    />
  )
}
export default Search
