import { TASK_TARGET_FAIL, TASK_TARGET_PENDING, TASK_TARGET_SUCCESS } from "../../../actions/types";

const initialState = {
  tasksTarget: [],
};

const tasks_target_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case TASK_TARGET_SUCCESS:
        return {
          ...state,
          tasksTarget: payload.tasksTarget,
          
        };
      case TASK_TARGET_PENDING:
        return {
          ...state,
          tasksTarget: payload.tasksTarget,
        };
      case TASK_TARGET_FAIL:
        return {
          ...state,
          tasksTarget: [],
        };
        default:
      return state;
    }
}
export default tasks_target_reducer;