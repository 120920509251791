import {
    ROLE_SUCCESS,
    ROLE_FAIL,
    ROLE_PENDING,
    ADD_ROLE_SUCCESS,
    ADD_ROLE_FAIL,
    ADD_ROLE_PENDING,
    UPDATE_ROLE_SUCCESS,
    UPDATE_ROLE_FAIL,
    UPDATE_ROLE_PENDING,
} from "../../actions/types";

const initialState = { 
    roles: [],
    addRoles : '',
    updateRole : '',
    };

const roles_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case ROLE_SUCCESS:
            return {
                ...state,
                roles: payload.roles,
            };
        case ROLE_PENDING:
            return {
                ...state,
                roles: payload.roles,
            };
        case ROLE_FAIL:
            return {
                ...state,
                roles: []
            };
        case ADD_ROLE_SUCCESS:
            return {
                ...state,
                addRoles: payload.addRoles,
            };
        case ADD_ROLE_PENDING:
            return {
                ...state,
                addRoles: payload.addRoles,
            };
        case ADD_ROLE_FAIL:
            return {
                ...state,
                addRoles: false
            };
            case UPDATE_ROLE_SUCCESS:
                return {
                    ...state,
                    updateRole: payload.updateRole,
                };
            case UPDATE_ROLE_PENDING:
                return {
                    ...state,
                    updateRole: payload.updateRole,
                };
            case UPDATE_ROLE_FAIL:
                return {
                    ...state,
                    updateRole: false
                };
        default:
            return state;
    }
}
export default roles_reducer