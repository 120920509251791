import {
    TASK_TYPE_SUCCESS,
    TASK_TYPE_FAIL,
    SET_MESSAGE,
    TASK_TYPE_PENDING,
    ADD_TASK_TYPE_SUCCESS,
    ADD_TASK_TYPE_FAIL,
    ADD_TASK_TYPE_PENDING,
    UPDATE_TASK_TYPE_SUCCESS,
    UPDATE_TASK_TYPE_FAIL,
    UPDATE_TASK_TYPE_PENDING
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";


export const getTaskTypes = (pageNumber, pageSize, searchQuery) => async(dispatch) => {
    dispatch({
        type: TASK_TYPE_PENDING,
        payload: { taskTypes: "isLoading" },
    });
    return await UserService.getTaskTypes(pageNumber, pageSize, searchQuery).then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: TASK_TYPE_SUCCESS,
                    payload: { taskTypes : data.data },
                }) :
                dispatch({
                    type: TASK_TYPE_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: TASK_TYPE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

//add product
export const addTaskTypes = (body) => async(dispatch) => {
    dispatch({
        type: ADD_TASK_TYPE_PENDING,
        payload: { addTaskTypes: "isLoading" },
    });
    return await UserService.addTaskType(body).then(
        (data) => {
            data.data.status ? toast.success("Task Type added Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: ADD_TASK_TYPE_SUCCESS,
                    payload: { addTaskTypes : data.data.status },
                }) :
                dispatch({
                    type: ADD_TASK_TYPE_FAIL,
                    payload: { addTaskTypes : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: ADD_TASK_TYPE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

//update product
export const updateTaskTypes = (body) => async(dispatch) => {
    dispatch({
        type: UPDATE_TASK_TYPE_PENDING,
        payload: { updateTaskTypes: "isLoading" },
    });
    return await UserService.updateTaskType(body).then(
        (data) => {
            data.data.status ? toast.success("Task Type updated Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: UPDATE_TASK_TYPE_SUCCESS,
                    payload: { updateTaskTypes : data.data.status },
                }) :
                dispatch({
                    type: UPDATE_TASK_TYPE_FAIL,
                    payload: { updateTaskTypes : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_TASK_TYPE_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};