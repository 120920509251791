import {
  CUSTOMER_RANK_SUCCESS,
  CUSTOMER_RANK_FAIL,
  SET_MESSAGE,
  CUSTOMER_RANK_PENDING,
  ADD_CUSTOMER_RANK_PENDING,
  ADD_CUSTOMER_RANK_FAIL,
  ADD_CUSTOMER_RANK_SUCCESS,
  UPDATE_CUSTOMER_RANK_PENDING,
  UPDATE_CUSTOMER_RANK_SUCCESS,
  UPDATE_CUSTOMER_RANK_FAIL,
} from "../types";
import UserService from "../../services/user.service";
import { toast } from "react-toastify";

export const getCustomerRank = (pageNumber, pageSize, searchQuery) => async (dispatch) => {
  dispatch({
    type: CUSTOMER_RANK_PENDING,
    payload: { customerRank: "isLoading" },
  });
  return await UserService.getCustomerRank(pageNumber, pageSize, searchQuery)
    .then(
      (data) => {
        data.data.status
          ? dispatch({
              type: CUSTOMER_RANK_SUCCESS,
              payload: { customerRank: data.data },
            })
          : dispatch({
              type: CUSTOMER_RANK_FAIL,
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: CUSTOMER_RANK_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};


// Add CustomerRank
export const addCustomerRank = (body) => async (dispatch) => {
  dispatch({
    type: ADD_CUSTOMER_RANK_PENDING,
    payload: { addCustomerRank: "isLoading" },
  });
  return await UserService.addCustomerRank(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("CustomerRank added Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: ADD_CUSTOMER_RANK_SUCCESS,
              payload: { addCustomerRank: data.data.status },
            })
          : dispatch({
              type: ADD_CUSTOMER_RANK_FAIL,
              payload: { addCustomerRank: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: ADD_CUSTOMER_RANK_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};

// Update CustomerRank
export const updateCustomerRank = (body) => async (dispatch) => {
  dispatch({
    type: UPDATE_CUSTOMER_RANK_PENDING,
    payload: { updateCustomerRank: "isLoading" },
  });
  return await UserService.updateCustomerRank(body)
    .then(
      (data) => {
        data.data.status
          ? toast.success("CustomerRank updated Successfully !", {
              autoClose: 2000,
            })
          : toast.error(data.data.message, { autoClose: 2000 });
        data.data.status
          ? dispatch({
              type: UPDATE_CUSTOMER_RANK_SUCCESS,
              payload: { updateCustomerRank: data.data.status },
            })
          : dispatch({
              type: UPDATE_CUSTOMER_RANK_FAIL,
              payload: { updateCustomerRank: data.data.status },
            });
        return Promise.resolve();
      },
      (error) => {
        console.log("error......", error);
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();

        dispatch({
          type: UPDATE_CUSTOMER_RANK_FAIL,
        });

        dispatch({
          type: SET_MESSAGE,
          payload: message,
        });

        return Promise.reject();
      }
    )
    .catch((error) => {
      console.log(error);
    });
};