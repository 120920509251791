import {
    CALL_AVERAGE_TAB_SUCCESS,
    CALL_AVERAGE_TAB_FAIL,
    CALL_AVERAGE_TAB_PENDING,
  } from "../../actions/types";
  
  const initialState = {
    callAvgTab: [],
  };
  
  const call_avg_tab_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case CALL_AVERAGE_TAB_SUCCESS:
        return {
          ...state,
          callAvgTab: payload.callAvgTab,
        };
      case CALL_AVERAGE_TAB_PENDING:
        return {
          ...state,
          callAvgTab: payload.callAvgTab,
        };
      case CALL_AVERAGE_TAB_FAIL:
        return {
          ...state,
          callAvgTab: [],
        };
      default:
        return state;
    }
  };
  export default call_avg_tab_reducer;