import {
    LOCATION_TYPE_SUCCESS,
    LOCATION_TYPE_PENDING,
    LOCATION_TYPE_FAIL,
    ADD_LOCATION_TYPE_SUCCESS,
    ADD_LOCATION_TYPE_PENDING,
    ADD_LOCATION_TYPE_FAIL,
    UPDATE_LOCATION_TYPE_SUCCESS,
    UPDATE_LOCATION_TYPE_PENDING,
    UPDATE_LOCATION_TYPE_FAIL,
} from "../../actions/types";

const initialState = {
    locationTypes: [],
    addLocationType: '',
    updateLocationType: ''
};

const location_type_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case LOCATION_TYPE_SUCCESS:
            return {
                ...state,
                locationTypes: payload.locationTypes,
            };
        case LOCATION_TYPE_PENDING:
            return {
                ...state,
                locationTypes: payload.locationTypes,
            };
        case LOCATION_TYPE_FAIL:
            return {
                ...state,
                locationTypes: []
            };
        case ADD_LOCATION_TYPE_SUCCESS:
            return {
                ...state,
                addLocationType: payload.addLocationType,
            };
        case ADD_LOCATION_TYPE_PENDING:
            return {
                ...state,
                addLocationType: payload.addLocationType,
            };
        case ADD_LOCATION_TYPE_FAIL:
            return {
                ...state,
                addLocationType: false
            };
        case UPDATE_LOCATION_TYPE_SUCCESS:
            return {
                ...state,
                updateLocationType: payload.updateLocationType,
            };
        case UPDATE_LOCATION_TYPE_PENDING:
            return {
                ...state,
                updateLocationType: payload.updateLocationType,
            };
        case UPDATE_LOCATION_TYPE_FAIL:
            return {
                ...state,
                updateLocationType: false
            };
        default:
            return state;
    }
}
export default location_type_reducer