import React from 'react'
import { Button, IconButton } from '@mui/material';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';

const ViewButton = () => {
  return (
    <Button
      id="basic-button"
      startIcon={
        <RemoveRedEyeOutlinedIcon
          style={{ fontSize: 24 }}
        />
      }
      sx={{
        color: "#505157",
        backgroundColor: "#FFFFFF",
        minWidth: "32px !important", 
        height: "32px", 
        width: "32px",  
        display: "flex", 
        justifyContent: "center",
        alignItems: "center", 
        "& .MuiButton-startIcon": {
          margin: "0",
        },
        "&:hover": {
          color: "#2F54EB",
          borderColor: "#2F54EB",
          "& .MuiButton-startIcon": {
            color: "#2F54EB",
          },
        },
      }}
    >
    </Button>
  )
}

export default ViewButton;
