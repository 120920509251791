import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getDoctors } from "../../actions/userActions/doctorAction";
import Loader from "../subComponents/Loader";
import { getCities } from "../../actions/userActions/cityAction";
import ServerSideSearch from '../subComponents/search/ServerSideSearch';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import AddButton from "../subComponents/buttons/AddButton";
import { IconButton,Tooltip } from "@mui/material";
import Pagination from '../paginatioin/pagination';
import EditButton from "../subComponents/buttons/EditButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

function Doctors() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedCity, setSelectedCity] = useState(1);
  const [page, setPage] = useState(1);
  const [cityFilters, setCityFilters] = useState({
    textSearch: "",
    countryId: "1",
  });
  const doctors = useSelector((state) => state.doctors_reducer.doctors);
  const allCities = useSelector((state) => state.cities_reducer.cities);
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [filters, setFilters] = useState({
    textSearch: '',
  })
  const [selected, setSelected] = useState({
    textSearch: '',
  })
  const cityBody = {
    filter: cityFilters,
    pageNumber: -1,
    pageSize: 0,
  };
  const doctorBody = {
    pageNumber: page,
    pageSize: 10,
    textSearch: filters.textSearch,
    cityId: selectedCity,
  };

  useEffect(() => {
    dispatch(getCities(cityBody));
  }, []);

  useEffect(() => {
    selectedCity && dispatch(getDoctors(doctorBody));
  }, [selectedCity, page, filters]);

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
  };
  const handleAddDoctorClick = () => {
    history.push('/admin/AddDoctors');
  };
  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 1) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }
  return (
    <>
      <div>
        <div className="row">
          <div className="col-4 md-5">
            <h2 className="d-inline-block">Doctors</h2>
            {/* <p style={{ fontSize: "14px" }}>
              Manage all your Doctors here{" "}
            </p> */}
          </div>
          <div className="col-8 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <ServerSideSearch
              placeholder="Search Doctor"
              onChange={handleSearch}
              value={selected.textSearch}
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCity}
                label="City"
                onChange={handleCityChange}
                MenuProps={{ PaperProps: { style: { maxHeight: 160 } } }}
                style={{ maxHeight: 40, borderRadius: "2px", width: "220px" }}
              >
                {allCities === "isLoading" && (
                  <MenuItem disabled>
                    Loading...
                  </MenuItem>
                )}

                {allCities !== "isLoading" && allCities.length === 0 && (
                  <MenuItem disabled>
                    No data
                  </MenuItem>
                )}

                {allCities !== "isLoading" && allCities.length !== 0 && allCities[0].cities
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(city => (
                    <MenuItem key={city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            {permissions.includes("CanAddDoctor") && (
              <AddButton onClick={handleAddDoctorClick} text="+ Add Doctor" />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Specialization</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Designation</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Status</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {doctors === "isLoading" && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td
                      colSpan={5}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {doctors !== "isLoading" && doctors.length !== 0 &&
                  doctors[0].doctors.length === 0 && (
                    <tr className="text-center" style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}>
                      <td colSpan={5}>No data found</td>
                    </tr>
                  )}

                {doctors !== "isLoading" && doctors.length !== 0 &&
                  doctors[0].doctors
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((row, i) => (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}
                      >
                        <td style={{verticalAlign:"middle"}}>{row.name}</td>
                        <td style={{verticalAlign:"middle"}}>{row.specializationName}</td>
                        <td style={{verticalAlign:"middle"}}>{row.rankName}</td>
                        <td style={{verticalAlign:"middle"}}>
                          <span
                            style={{
                              color: row.active
                                ? "#2196F3"
                                : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                              borderRadius: "100px",
                              border: `1px solid ${row.active
                                ? "var(--primary-main, #2196F3)"
                                : "var(--chip-defaultEnabledBorder, #BDBDBD)"
                                }`,
                              padding: "3px 10px",
                            }}
                          >
                            {row.active ? "Active" : "In-active"}
                          </span>
                        </td>
                        <td style={{verticalAlign:"middle"}}>
                          {permissions.includes("CanUpdateDoctor") && (
                            <Tooltip title="Edit Doctor" arrow enterDelay={500} placement="bottom">
                              <Link
                                to={{
                                  pathname: "/admin/UpdateDoctors",
                                  doctors: { row },
                                  id: row.id,
                                }}
                              >
                                 <IconButton>
                                  <EditOutlinedIcon 
                                  />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        {doctors !== 'isLoading' && doctors.length !== 0 && (
          <div className="task-pagination-wrapper">
            <Pagination
              total={
                doctors[0].page != undefined &&
                doctors[0].page.totalPages * doctors[0].page.pageSize
              }
              current={doctors[0].page != undefined && doctors[0].page.pageNumber}
              pageSize={doctors[0].page != undefined && doctors[0].page.pageSize}
              onChange={(current) => setPage(current)}
              showLessItems
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Doctors;
