import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getTaskTypes } from "../../actions/userActions/taskTypeAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import Pagination from '../paginatioin/pagination';
import { SIZE_OF_PAGE } from "../../actions/types";

function TaskTypes() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [query, setQuery] = useState("");
  const [previousQuery, setPreviousQuery] = useState("");
  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const pageNumber = currentPage;

    if (query !== previousQuery) {
      setCurrentPage(1);
    }
    dispatch(getTaskTypes(pageNumber, SIZE_OF_PAGE, query));
    setPreviousQuery(query);

  }, [currentPage, query]);
  const taskTypes = useSelector((state) => state.task_types_reducer.taskTypes);
  const totalPages = taskTypes.totalPages;
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));


  const handleAddTaskTypeClick = () => {
    history.push('/admin/addTaskTypes');
  };
  const handleSearchChange = (query) => {
    setQuery(query);
    setCurrentPage(1);
    history.push(`?page=1&search=${query}`);
  };
  const handlePageChange = (page) => {
    setCurrentPage(page);
    history.push(`?page=${page}&search=${query}`);
  };
  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 md-5">
            <h2 className="d-inline-block">Task Types</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <Search
              setText={handleSearchChange}
              text={query}
              placeholder="Search Type"
            />
            {permissions.includes("CanAddTaskType") && (
              <AddButton onClick={handleAddTaskTypeClick} text="+ Add Type" />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Status</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {taskTypes === "isLoading" && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td
                      colSpan={3}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                { taskTypes.result !== "isLoading" && taskTypes.length === 0 && (
                    <tr className="text-center" style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}>
                      <td colSpan={3}>No data found</td>
                    </tr>
                  )}

                {taskTypes !== "isLoading" && taskTypes.result &&
                  taskTypes.result.map((row, i) => (
                    <tr
                      key={i}
                      style={{
                        backgroundColor: "transparent",
                        "--x-table-accent-bg": "transparent",
                      }}
                    >
                      <td>{row.name}</td>
                      <td
                        style={{
                          whiteSpace: "pre-line",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                          verticalAlign: "middle",
                        }}
                      >
                        <div
                          style={{
                            maxHeight:
                              isExpanded || row.description.length <= 100
                                ? "none"
                                : "3.4em",
                            overflow: "hidden",
                            position: "relative",
                            width: "67%",
                          }}
                        >
                          {row.description}
                          {row.description.length > 100 && (
                            <span
                              style={{position:"absolute", right:"0px", bottom:"0", cursor:"pointer", fontSize:"20px"}}
                              onClick={toggleExpansion}
                            >
                              ...
                            </span>
                          )}
                        </div>
                      </td>
                      <td>
                        <span
                          style={{
                            color: row.active
                              ? "#2196F3"
                              : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                            borderRadius: "100px",
                            border: `1px solid ${row.active
                              ? "var(--primary-main, #2196F3)"
                              : "var(--chip-defaultEnabledBorder, #BDBDBD)"
                              }`,
                            padding: "3px 10px",
                          }}
                        >
                          {row.active !== undefined ? (row.active ? "Active" : "In-active") : "Active"}
                        </span>

                      </td>

                     
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='task-pagination-wrapper'>
          <Pagination
            total={totalPages}
            current={currentPage}
            pageSize={1}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default TaskTypes;
