import {
    LOGIN_SUCCESS,
    LOGIN_PENDING,
    LOGIN_FAIL,
    LOGOUT,
  } from "../../actions/types";
  
 
  
  const initialState =  { isLoggedIn: false, user:null ,isLogging:false };
  
   const reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case LOGIN_PENDING:
      return {
          ...state,
          isLoggedIn: false,
          isLogging:true,
          user: null,
        };
      case LOGIN_SUCCESS:
      return {
          ...state,
          isLoggedIn: true,
          isLogging:false,
          user: payload.user,
        };
      case LOGIN_FAIL:
        return {
          ...state,
          isLoggedIn: false,
          isLogging:false,
          user: null,
        };
      case LOGOUT:
        return {
          ...state,
          isLoggedIn: false,
          isLogging:false,
          user: null,
        };
      default:
        return state;
    }
  }
  export default reducer;
  