import axios from "axios";
import authHeader from "./auth/auth-header";
import { interceptor } from "./interceptor";
import { BASE_URL } from "../Constatns";

const API_URL = BASE_URL + "";

const allCitiesDataFilter = {
  filter: {
    textSearch: "",
    countryId: 1,
  },
  pageNumber: -1,
  pageSize: 0,
};

class TaskService {
  async getTasks(body) {
    interceptor();
    return axios.post(API_URL + "Task/GetEmployeeTask", body, {
      headers: authHeader(),
    });
  }

  async getTaskDetails(id) {
    interceptor();
    return axios.get(API_URL + "Task?P_TASK_ID=" + id, { headers: authHeader() });
  }

  

  async getTaskNotes(id) {
    interceptor();
    return axios.get(API_URL + "Task/GetTaskNotes?taskId=" + id, { headers: authHeader() });
  }
  async getTaskDoctors(body) {
    interceptor();
    return axios.post(API_URL + "Doctor/All?P_CITY_ID=" + body.cityId,body, {
      headers: authHeader(),
    });
  }

  async updateSubTask(body) {
    interceptor();
    return axios.post(API_URL + "Task/UpdateSubTask" , body, {
      headers: authHeader(),
    });
  }

  async getTaskPracticeLocations(id) {
    const payload ={
      "filter": {
        "textSearch": "",
        "city": id
      },
      "pageNumber": -1,
      "pageSize": 0
    };
    interceptor();
    return axios.post(API_URL + "PracticeLocation/All", payload,{ 
      headers: authHeader(),
    });
  }

  async getTaskProducts(body) {
    interceptor();
    return axios.post(API_URL + "Product/All" , body, { headers: authHeader() });
  }

  async getTaskSubOrdinates(id) {
    interceptor();
    return axios.get(API_URL + "SubOrdinates?P_EMPLOYEE_ID=" + id, {
      headers: authHeader(),
    });
  }

  async getSubOrdinates(id) {
    interceptor();
    return axios.get(API_URL + "Employee/SubOrdinates?P_EMPLOYEE_ID=" + id, {
      headers: authHeader(),
    });
  }

  async getTaskSubTasks() {
    interceptor();
    return axios.get(API_URL + "SubTask/All", { headers: authHeader() });
  }

  async getTaskPriorities() {
    interceptor();
    return axios.get(API_URL + "TaskPriority/All", { headers: authHeader() });
  }

  async getTaskStatuses() {
    interceptor();
    return axios.get(API_URL + "TaskStatus/All", { headers: authHeader() });
  }

  async getTaskTypes() {
    interceptor();
    return axios.get(API_URL + "TaskType/All", { headers: authHeader() });
  }

  async getTaskCities() {
    interceptor();
    return axios.post(BASE_URL + "City/All", allCitiesDataFilter, {
      headers: authHeader(),
    });
  }

  async addTask(body) {
    interceptor();
    return axios.post(API_URL + "Task/Add", body, { headers: authHeader() });     
  }

async addTaskNotes(noteBody) {
  interceptor();
  return axios.post(API_URL + "Task/AddTaskNotes", noteBody, { headers: authHeader() });
}
  async getTaskTarget(body) {
    interceptor();
    return axios.post(API_URL + "Task/GetTaskTarget", body, { headers: authHeader() });
  }
}



export default new TaskService();
