import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@mui/material';


export const CancelButton = ({ onClick }) => {
    return (
        <Button
            onClick={onClick}
            style={{
                width: "114px",
                height: "40px",
                padding: "6px 14px",
                gap: "8px",
                borderRadius: "2px",
                border: "1px solid #D9D9D9",
                background: "#FFFFFF",
                boxShadow: "2px #00000004",
                color: "#000000DE",
                textTransform: "none",
                cursor: "pointer",
                transition: "background-color 0.3s ease, color 0.3s ease, border-color 0.3s ease"
            }}
            onMouseOver={(e) => {
                e.currentTarget.style.backgroundColor = "#FFFFFF";
                e.currentTarget.style.color = "#004BB4";
                e.currentTarget.style.borderColor = "#004BB4";
            }}
            onMouseOut={(e) => {
                e.currentTarget.style.backgroundColor = "#FFFFFF";
                e.currentTarget.style.color = "#000000DE";
                e.currentTarget.style.borderColor = "#D9D9D9";
            }}
        >
            Cancel
        </Button>
    );
};

CancelButton.propTypes = {
    onClick: PropTypes.func.isRequired,
};

export default CancelButton;