import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getUnitTypes } from '../../actions/userActions/unitTypeAction'
import InnerHeader from '../InnerHeader/InnderHeader'
import AUUnitTypeModal from './AUUnitTypeModal'
import TableComponent from '../subComponents/table/TableComponent'
import { CircularProgress } from '@mui/material'
import UnitTypeBlueIcon from '../../assets/images/unit_type_blue_icon.svg'
import StatusblueIcon from '../../assets/images/status_blue_icon.svg'
import DescriptionBlueIcon from '../../assets/images/descrition_blue_icon.svg'
import ViewModal from '../subComponents/modals/ViewModal'
import DeleteModal from '../subComponents/modals/DeleteModal'
import Pagination from '../paginatioin/pagination'

function UnitTypes() {
  const [unitType, setStoreType] = useState()

  const [openAdd, setOpenAdd] = useState(false)
  const handleOpenAdd = () => setOpenAdd(true)
  const handleCloseAdd = () => setOpenAdd(false)

  const [openEdit, setOpenEdit] = useState(false)
  const handleOpenEdit = () => setOpenEdit(true)
  const handleCloseEdit = () => {
    setOpenEdit(false)
    setStoreType(null)
  }
  const [page, setPage] = useState(1)

  const [Filters, setFilters] = useState({
    textSearch: '',
    isActive: true,
  })
  const [selected, setSelected] = useState({
    textSearch: '',
    isActive: true,
  })
  const body = {
    filter: Filters,
    pageNumber: page,
    pageSize: 10,
  }
  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 4) {
      setTimeout(() => {
        setFilters({ ...Filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...Filters, ['textSearch']: e.target.value })
    }
  }
  const [openView, setOpenView] = useState(false)
  const handleOpenView = () => {
    setOpenView(true)
  }
  const handleCloseView = () => {
    setOpenView(false)
    setStoreType(null)
  }

  const [openDelete, setOpenDelete] = useState(false)
  const handleOpenDelete = () => setOpenDelete(true)
  const handleCloseDelete = () => setOpenDelete(false)

  const dispatch = useDispatch()
  const addUnitTypes = useSelector(
    (state) => state.unit_types_reducer.addUnitTypes,
  )
  const updateUnitTypes = useSelector(
    (state) => state.unit_types_reducer.updateUnitType,
  )
  console.log(body)
  useEffect(() => {
    dispatch(getUnitTypes(body))
  }, [addUnitTypes, updateUnitTypes, page, Filters])

  const unitTypes = useSelector((state) => state.unit_types_reducer.unit_types)
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const [query, setQuery] = useState('')

  const getPaginationNo = (pageNum) => {
    const object = []
    if (unitTypes !== 'isLoading' && unitTypes.length !== 0) {
      for (let i = 0; i < unitTypes.page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }

  return (
    <>
      <div className="container mt-5">
        <InnerHeader
          title="Unit Types"
          description="Manage all your unit types or add new one"
          handleSearch={handleSearch}
          value={selected.textSearch}
          serverSideSearch={true}
          searchPlaceholder="Search unit type"
          permission={permissions.includes('CanAddUnitType')}
          buttonTitle="+ Add Unit Type"
          onClick={handleOpenAdd}
        />
        <div className="row">
          <div className="col-md-12">
            {unitTypes === 'isLoading' && (
              <div style={{ height: '70vh' }}>
                <div className="d-flex my-5" style={{ height: '100%' }}>
                  <CircularProgress
                    sx={{ margin: 'auto', color: 'rgba(0, 45, 114, 1)' }}
                  />
                </div>
              </div>
            )}
            {unitTypes && unitTypes !== 'isLoading' && (
              <>
                <TableComponent
                  data={unitTypes.unitTypes}
                  headers={['Name', 'Description', 'Status']}
                  keys={['name', 'description', 'active']}
                  setData={setStoreType}
                  doubleTitle={false}
                  onEyeClick={handleOpenView}
                  onEditClick={handleOpenEdit}
                  onDeleteClick={handleOpenDelete}
                />
                {/* <div className="row">
                  <div className="col-12">
                    <div className="task-pagination-wrapper">
                      {getPaginationNo(page)}
                    </div>
                  </div>
                </div> */}
                {unitTypes !== 'isLoading' && (
                  <div className="task-pagination-wrapper">
                    <Pagination
                      total={
                        unitTypes.page != undefined &&
                        unitTypes.page.totalPages * unitTypes.page.pageSize
                      }
                      current={
                        unitTypes.page != undefined && unitTypes.page.pageNumber
                      }
                      pageSize={
                        unitTypes.page != undefined && unitTypes.page.pageSize
                      }
                      onChange={(current) => setPage(current)}
                      showLessItems
                    />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        title="Delete Store"
        type="unit"
        open={openDelete}
        onClose={handleCloseDelete}
      />
      {unitType && (
        <ViewModal
          title="Store"
          headings={['Name', 'Description', 'Status']}
          keys={['name', 'description', 'active']}
          icons={[UnitTypeBlueIcon, DescriptionBlueIcon, StatusblueIcon]}
          data={unitType}
          open={openView}
          onClose={handleCloseView}
        />
      )}
      <AUUnitTypeModal
        type="add"
        onViewClose={handleCloseView}
        open={openAdd}
        onClose={handleCloseAdd}
      />
      {unitType && (
        <AUUnitTypeModal
          type="update"
          onViewClose={handleCloseView}
          unitType={unitType}
          open={openEdit}
          onClose={handleCloseEdit}
        />
      )}
    </>
  )
}

export default UnitTypes
