import {
    DOCTOR_SPECIALIZATION_SUCCESS,
    DOCTOR_SPECIALIZATION_PENDING,
    DOCTOR_SPECIALIZATION_FAIL,
    ADD_DOCTOR_SPECIALIZATION_SUCCESS,
    ADD_DOCTOR_SPECIALIZATION_PENDING,
    ADD_DOCTOR_SPECIALIZATION_FAIL,
    UPDATE_DOCTOR_SPECIALIZATION_SUCCESS,
    UPDATE_DOCTOR_SPECIALIZATION_PENDING,
    UPDATE_DOCTOR_SPECIALIZATION_FAIL,
    SET_MESSAGE
} from "../types";
import { toast} from 'react-toastify'; 
import UserService from "../../services/user.service";

//All doctor specializations

export const getDoctorSpecializations = () => async(dispatch) => {
    dispatch({
        type: DOCTOR_SPECIALIZATION_PENDING,
        payload: { specializations: "isLoading" },
    });
    return await UserService.getDoctorSpecialization().then(
        (data) => {
            data.data.status ?
                dispatch({
                    type: DOCTOR_SPECIALIZATION_SUCCESS,
                    payload: { specializations: data.data.result},
                }) :
                dispatch({
                    type: DOCTOR_SPECIALIZATION_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: DOCTOR_SPECIALIZATION_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    });
};

    //add doctor specialization
export const addDoctorSpecializations = (body) => async(dispatch) => {
    dispatch({
        type: ADD_DOCTOR_SPECIALIZATION_PENDING,
        payload: { addSpecializations: "isLoading" },
    });
    return await UserService.addDoctorSpecialization(body).then(
        (data) => {
            data.data.status ? toast.success("Specialization added Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: ADD_DOCTOR_SPECIALIZATION_SUCCESS,
                    payload: { addSpecializations : data.data.status },
                }) :
                dispatch({
                    type: ADD_DOCTOR_SPECIALIZATION_FAIL,
                    payload: { addSpecializations : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: ADD_DOCTOR_SPECIALIZATION_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    }).finally(()=>{
        dispatch({
          type: ADD_DOCTOR_SPECIALIZATION_FAIL,
        });
      });
};

//update doctor speciaizations
export const updateDoctorSpecializations = (body) => async(dispatch) => {
    dispatch({
        type: UPDATE_DOCTOR_SPECIALIZATION_PENDING,
        payload: { updateSpecializations: "isLoading" },
    });
    return await UserService.updateDoctorSpecialization(body).then(
        (data) => {
            data.data.status ? toast.success("Specialization updated Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: UPDATE_DOCTOR_SPECIALIZATION_SUCCESS,
                    payload: { updateSpecializations : data.data.status },
                }) :
                dispatch({
                    type: UPDATE_DOCTOR_SPECIALIZATION_FAIL,
                    payload: { updateSpecializations : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_DOCTOR_SPECIALIZATION_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    }).finally(()=>{
        dispatch({
          type: UPDATE_DOCTOR_SPECIALIZATION_FAIL,
        });
      });
}