import React, { useState, useEffect } from 'react';
import GMap from '../gMap/GMap.jsx';
import { GOOGLE_MAP_API_KEY } from '../../Constatns.js';
import '../locate/Locate.css';
import Loader from '../subComponents/Loader.jsx';



const Locate = (props) =>{
    const [loadMap, setLoadMap] = useState(false);
    const icon = 'https://cdn2.iconfinder.com/data/icons/social-media-8/512/pointer.png';
    const markerList = [
        { lat: 59.2967322, lng: 18.0009393, icon: icon, title: 'title1' },
        { lat: 59.2980245, lng: 17.9971503, icon: icon, title: 'title2' },
        { lat: 59.2981078, lng: 17.9980875, icon: icon, title: 'title3' },
        { lat: 59.2987638, lng: 17.9917639, icon: icon, title: 'title4' }
      ];
      const loadGoogleMapScript = (callback) => {
        if (typeof window.google === 'object' && typeof window.google.maps === 'object') {
          callback();
        } else {
          const googleMapScript = document.createElement("script");
          googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;
          window.document.body.appendChild(googleMapScript);
          googleMapScript.addEventListener("load", callback);
        }
      }
    useEffect(() => {
      loadGoogleMapScript(() => {
        setLoadMap(true)
      });
    }, []);

    return (
      <><div className='locate-map-container' id="map">
      {!loadMap ? <div className='mt-40'><Loader colored={true} /> </div> : <GMap markerList={markerList} />}
    </div></>
        
      );
}

export default Locate;