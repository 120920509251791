import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: "AIzaSyDEzB-EBsbfHtf0itj4eOv4sr72yhxfwFY",
  authDomain: "field-force-a761f.firebaseapp.com",
  projectId: "field-force-a761f",
  storageBucket: "field-force-a761f.appspot.com",
  messagingSenderId: "1062330378183",
  appId: "1:1062330378183:web:42b6e6a1d0e5e41ddf5be2"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export { messaging };
