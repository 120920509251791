import {
    STORE_SUCCESS,
    STORE_FAIL,
    STORE_PENDING,
    ADD_STORE_SUCCESS,
    ADD_STORE_FAIL,
    ADD_STORE_PENDING,
    UPDATE_STORE_SUCCESS,
    UPDATE_STORE_FAIL,
    UPDATE_STORE_PENDING,
} from "../../actions/types";

const initialState = { 
    stores: [],
    addStore : '',
    updateStore : '',
    };

const stores_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case STORE_SUCCESS:
            return {
                ...state,
                stores: payload.stores,
            };
        case STORE_PENDING:
            return {
                ...state,
                stores: payload.stores,
            };
        case STORE_FAIL:
            return {
                ...state,
                stores: []
            };
        case ADD_STORE_SUCCESS:
            return {
                ...state,
                addStore: payload.addStore,
            };
        case ADD_STORE_PENDING:
            return {
                ...state,
                addStore: payload.addStore,
            };
        case ADD_STORE_FAIL:
            return {
                ...state,
                addStore: false
            };
            case UPDATE_STORE_SUCCESS:
                return {
                    ...state,
                    updateStore: payload.updateStore,
                };
            case UPDATE_STORE_PENDING:
                return {
                    ...state,
                    updateStore: payload.updateStore,
                };
            case UPDATE_STORE_FAIL:
                return {
                    ...state,
                    updateStore: false
                };
        default:
            return state;
    }
}
export default stores_reducer