import {
  DOCTOR_WITH_CUSTOMER_SUCCESS,
  DOCTOR_WITH_CUSTOMER_FAIL,
  DOCTOR_WITH_CUSTOMER_PENDING,
} from "../../actions/types";

const initialState = {
  doctorsWithCustomer: [],
};

const doctors_with_customer_reducer = (state = initialState, action) => {
  const { type, payload } = action;
  console.log();
  switch (type) {
    case DOCTOR_WITH_CUSTOMER_SUCCESS:
      return {
        ...state,
        doctorsWithCustomer: payload.doctors,
      };
    case DOCTOR_WITH_CUSTOMER_PENDING:
      return {
        ...state,
        doctorsWithCustomer: payload.doctors,
      };
    case DOCTOR_WITH_CUSTOMER_FAIL:
      return {
        ...state,
        doctorsWithCustomer: [],
      };
    default:
      return state;
  }
};
export default doctors_with_customer_reducer;
