//login
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_PENDING = "LOGIN_PENDING";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
//messages
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";

//CallAverageEmp
export const CALL_AVERAGE_EMP_SUCCESS = "CALL_AVERAGE_EMP_SUCCESS";
export const CALL_AVERAGE_EMP_FAIL = "CALL_AVERAGE_EMP_FAIL";
export const CALL_AVERAGE_EMP_PENDING = "CALL_AVERAGE_EMP_PENDING";

//CallAveragePro
export const CALL_AVERAGE_PRO_SUCCESS = "CALL_AVERAGE_PRO_SUCCESS";
export const CALL_AVERAGE_PRO_FAIL = "CALL_AVERAGE_PRO_FAIL";
export const CALL_AVERAGE_PRO_PENDING = "CALL_AVERAGE_PRO_PENDING";

//CallAveragePract
export const  CALL_AVERAGE_PRACT_SUCCESS = "CALL_AVERAGE_PRACT_SUCCESS";
export const CALL_AVERAGE_PRACT_FAIL = "CALL_AVERAGE_PRACT_FAIL";
export const CALL_AVERAGE_PRACT_PENDING = "CALL_AVERAGE_PRACT_PENDING";

//CallAverageTab
export const  CALL_AVERAGE_TAB_SUCCESS = "CALL_AVERAGE_TAB_SUCCESS";
export const CALL_AVERAGE_TAB_FAIL = "CALL_AVERAGE_TAB_FAIL";
export const CALL_AVERAGE_TAB_PENDING = "CALL_AVERAGE_TAB_PENDING";

//CallAverageTasks
export const  CALL_AVERAGE_TASKS_SUCCESS = "CALL_AVERAGE_TASKS_SUCCESS";
export const CALL_AVERAGE_TASKS_FAIL = "CALL_AVERAGE_TASKS_FAIL";
export const CALL_AVERAGE_TASKS_PENDING = "CALL_AVERAGE_TASKS_PENDING";

//permissions
export const PERMISSION_SUCCESS = "PERMISSION_SUCCESS";
export const PERMISSION_PENDING = "PERMISSION_PENDING";
export const PERMISSION_FAIL = "PERMISSION_FAIL";
//All permissions
export const ALL_PERMISSION_SUCCESS = "ALL_PERMISSION_SUCCESS";
export const ALL_PERMISSION_PENDING = "ALL_PERMISSION_PENDING";
export const ALL_PERMISSION_FAIL = "ALL_PERMISSION_FAIL";
//permission Group
export const PERMISSION_GROUP_SUCCESS = "PERMISSION_GROUP_SUCCESS";
export const PERMISSION_GROUP_PENDING = "PERMISSION_GROUP_PENDING";
export const PERMISSION_GROUP_FAIL = "PERMISSION_GROUP_FAIL";
//Add permission
export const ADD_PERMISSION_SUCCESS = "ADD_PERMISSION_SUCCESS";
export const ADD_PERMISSION_PENDING = "ADD_PERMISSION_PENDING";
export const ADD_PERMISSION_FAIL = "ADD_PERMISSION_FAIL";
//Update permission
export const UPDATE_PERMISSION_SUCCESS = "UPDATE_PERMISSION_SUCCESS";
export const UPDATE_PERMISSION_PENDING = "UPDATE_PERMISSION_PENDING";
export const UPDATE_PERMISSION_FAIL = "UPDATE_PERMISSION_FAIL";

//Gender
export const GENDER_SUCCESS = "GENDER_SUCCESS";
export const GENDER_PENDING = "GENDER_PENDING";
export const GENDER_FAIL = "GENDER_FAIL";

//Employees
export const EMPLOYEE_SUCCESS = "EMPLOYEE_SUCCESS";
export const EMPLOYEE_PENDING = "EMPLOYEE_PENDING";
export const EMPLOYEE_FAIL = "EMPLOYEE_FAIL";

//Single Employee
export const SINGLE_EMPLOYEE_SUCCESS = "SINGLE_EMPLOYEE_SUCCESS";
export const SINGLE_EMPLOYEE_PENDING = "SINGLE_EMPLOYEE_PENDING";
export const SINGLE_EMPLOYEE_FAIL = "SINGLE_EMPLOYEE_FAIL";
//Employee Subordinates
export const EMPLOYEE_SUB_ORDINATES_SUCCESS = "EMPLOYEE_SUB_ORDINATES_SUCCESS";
export const EMPLOYEE_SUB_ORDINATES_PENDING = "EMPLOYEE_SUB_ORDINATES_PENDING";
export const EMPLOYEE_SUB_ORDINATES_FAIL = "EMPLOYEE_SUB_ORDINATES_FAIL";
//Add Emplyees
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const ADD_EMPLOYEE_PENDING = "ADD_EMPLOYEE_PENDING";
export const ADD_EMPLOYEE_FAIL = "ADD_EMPLOYEE_FAIL";
//Update Emplyees
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_PENDING = "UPDATE_EMPLOYEE_PENDING";
export const UPDATE_EMPLOYEE_FAIL = "UPDATE_EMPLOYEE_FAIL";

//Office Locations
export const OFFICE_LOCATION_SUCCESS = "OFFICE_LOCATION_SUCCESS";
export const OFFICE_LOCATION_PENDING = "OFFICE_LOCATION_PENDING";
export const OFFICE_LOCATION_FAIL = "OFFICE_LOCATION_FAIL";
//add office Location
export const ADD_OFFICE_LOCATION_SUCCESS = "ADD_OFFICE_LOCATION_SUCCESS";
export const ADD_OFFICE_LOCATION_PENDING = "ADD_OFFICE_LOCATION_PENDING";
export const ADD_OFFICE_LOCATION_FAIL = "ADD_OFFICE_LOCATION_FAIL";
//update office Location
export const UPDATE_OFFICE_LOCATION_SUCCESS = "UPDATE_OFFICE_LOCATION_SUCCESS";
export const UPDATE_OFFICE_LOCATION_PENDING = "UPDATE_OFFICE_LOCATION_PENDING";
export const UPDATE_OFFICE_LOCATION_FAIL = "UPDATE_OFFICE_LOCATION_FAIL";

//Sub Task
export const SUB_TASK_SUCCESS = "SUB_TASK_SUCCESS";
export const SUB_TASK_PENDING = "SUB_TASK_PENDING";
export const SUB_TASK_FAIL = "SUB_TASK_FAIL";
//Add Sub Task
export const ADD_SUB_TASK_SUCCESS = "ADD_SUB_TASK_SUCCESS";
export const ADD_SUB_TASK_PENDING = "ADD_SUB_TASK_PENDING";
export const ADD_SUB_TASK_FAIL = "ADD_SUB_TASK_FAIL";
//Update Sub Task
export const UPDATE_SUB_TASK_SUCCESS = "UPDATE_SUB_TASK_SUCCESS";
export const UPDATE_SUB_TASK_PENDING = "UPDATE_SUB_TASK_PENDING";
export const UPDATE_SUB_TASK_FAIL = "UPDATE_SUB_TASK_FAIL";

//Task Priority
export const TASK_PRIORITY_SUCCESS = "TASK_PRIORITY_SUCCESS";
export const TASK_PRIORITY_PENDING = "TASK_PRIORITY_PENDING";
export const TASK_PRIORITY_FAIL = "TASK_PRIORITY_FAIL";
//Add Task Priority
export const ADD_TASK_PRIORITY_SUCCESS = "ADD_TASK_PRIORITY_SUCCESS";
export const ADD_TASK_PRIORITY_PENDING = "ADD_TASK_PRIORITY_PENDING";
export const ADD_TASK_PRIORITY_FAIL = "ADD_TASK_PRIORITY_FAIL";
//Update Task Priority
export const UPDATE_TASK_PRIORITY_SUCCESS = "UPDATE_TASK_PRIORITY_SUCCESS";
export const UPDATE_TASK_PRIORITY_PENDING = "UPDATE_TASK_PRIORITY_PENDING";
export const UPDATE_TASK_PRIORITY_FAIL = "UPDATE_TASK_PRIORITY_FAIL";

//Task Type
export const TASK_TYPE_SUCCESS = "TASK_TYPE_SUCCESS";
export const TASK_TYPE_PENDING = "TASK_TYPE_PENDING";
export const TASK_TYPE_FAIL = "TASK_TYPE_FAIL";
//Add Task Type
export const ADD_TASK_TYPE_SUCCESS = "ADD_TASK_TYPE_SUCCESS";
export const ADD_TASK_TYPE_PENDING = "ADD_TASK_TYPE_PENDING";
export const ADD_TASK_TYPE_FAIL = "ADD_TASK_TYPE_FAIL";
//Update Task Type
export const UPDATE_TASK_TYPE_SUCCESS = "UPDATE_TASK_TYPE_SUCCESS";
export const UPDATE_TASK_TYPE_PENDING = "UPDATE_TASK_TYPE_PENDING";
export const UPDATE_TASK_TYPE_FAIL = "UPDATE_TASK_TYPE_FAIL";

//Task Status
export const TASK_STATUS_SUCCESS = "TASK_STATUS_SUCCESS";
export const TASK_STATUS_PENDING = "TASK_STATUS_PENDING";
export const TASK_STATUS_FAIL = "TASK_STATUS_FAIL";

//Task Target
export const TASK_TARGET_SUCCESS = "TASK_TARGET_SUCCESS";
export const TASK_TARGET_PENDING = "TASK_TARGET_PENDING";
export const TASK_TARGET_FAIL = "TASK_TARGET_FAIL";

//Team
export const TEAM_SUCCESS = "TEAM_SUCCESS";
export const TEAM_PENDING = "TEAM_PENDING";
export const TEAM_FAIL = "TEAM_FAIL";
//Add team
export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS";
export const ADD_TEAM_PENDING = "ADD_TEAM_PENDING";
export const ADD_TEAM_FAIL = "ADD_TEAM_FAIL";
//Update team
export const UPDATE_TEAM_SUCCESS = "UPDATE_TEAM_SUCCESS";
export const UPDATE_TEAM_PENDING = "UPDATE_TEAM_PENDING";
export const UPDATE_TEAM_FAIL = "UPDATE_TEAM_FAIL";

//Product
export const PRODUCT_SUCCESS = "PRODUCT_SUCCESS";
export const PRODUCT_PENDING = "PRODUCT_PENDING";
export const PRODUCT_FAIL = "PRODUCT_FAIL";
//Add product
export const ADD_PRODUCT_SUCCESS = "ADD_PRODUCT_SUCCESS";
export const ADD_PRODUCT_PENDING = "ADD_PRODUCT_PENDING";
export const ADD_PRODUCT_FAIL = "ADD_PRODUCT_FAIL";
//Update product
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS";
export const UPDATE_PRODUCT_PENDING = "UPDATE_PRODUCT_PENDING";
export const UPDATE_PRODUCT_FAIL = "UPDATE_PRODUCT_FAIL";

//Designation
export const DESIGNATION_SUCCESS = "DESIGNATION_SUCCESS";
export const DESIGNATION_PENDING = "DESIGNATION_PENDING";
export const DESIGNATION_FAIL = "DESIGNATION_FAIL";
//Add Designation
export const ADD_DESIGNATION_SUCCESS = "ADD_DESIGNATION_SUCCESS";
export const ADD_DESIGNATION_PENDING = "ADD_DESIGNATION_PENDING";
export const ADD_DESIGNATION_FAIL = "ADD_DESIGNATION_FAIL";
//Update Designation
export const UPDATE_DESIGNATION_SUCCESS = "UPDATE_DESIGNATION_SUCCESS";
export const UPDATE_DESIGNATION_PENDING = "UPDATE_DESIGNATION_PENDING";
export const UPDATE_DESIGNATION_FAIL = "UPDATE_DESIGNATION_FAIL";

//Department
export const DEPARTMENT_SUCCESS = "DEPARTMENT_SUCCESS";
export const DEPARTMENT_PENDING = "DEPARTMENT_PENDING";
export const DEPARTMENT_FAIL = "DEPARTMENT_FAIL";
//Add Department
export const ADD_DEPARTMENT_SUCCESS = "ADD_DEPARTMENT_SUCCESS";
export const ADD_DEPARTMENT_PENDING = "ADD_DEPARTMENT_PENDING";
export const ADD_DEPARTMENT_FAIL = "ADD_DEPARTMENT_FAIL";
//Update Department
export const UPDATE_DEPARTMENT_SUCCESS = "UPDATE_DEPARTMENT_SUCCESS";
export const UPDATE_DEPARTMENT_PENDING = "UPDATE_DEPARTMENT_PENDING";
export const UPDATE_DEPARTMENT_FAIL = "UPDATE_DEPARTMENT_FAIL";

//Employment Status
export const EMPLOYMENT_STATUS_SUCCESS = "EMPLOYMENT_STATUS_SUCCESS";
export const EMPLOYMENT_STATUS_PENDING = "EMPLOYMENT_STATUS_PENDING";
export const EMPLOYMENT_STATUS_FAIL = "EMPLOYMENT_STATUS_FAIL";
//Add Employment Status
export const ADD_EMPLOYMENT_STATUS_SUCCESS = "ADD_EMPLOYMENT_STATUS_SUCCESS";
export const ADD_EMPLOYMENT_STATUS_PENDING = "ADD_EMPLOYMENT_STATUS_PENDING";
export const ADD_EMPLOYMENT_STATUS_FAIL = "ADD_EMPLOYMENT_STATUS_FAIL";
//Update Employment Status
export const UPDATE_EMPLOYMENT_STATUS_SUCCESS =
  "UPDATE_EMPLOYMENT_STATUS_SUCCESS";
export const UPDATE_EMPLOYMENT_STATUS_PENDING =
  "UPDATE_EMPLOYMENT_STATUS_PENDING";
export const UPDATE_EMPLOYMENT_STATUS_FAIL = "UPDATE_EMPLOYMENT_STATUS_FAIL";

//City
export const CITY_SUCCESS = "CITY_SUCCESS";
export const CITY_PENDING = "CITY_PENDING";
export const CITY_FAIL = "CITY_FAIL";
//Add City
export const ADD_CITY_SUCCESS = "ADD_CITY_SUCCESS";
export const ADD_CITY_PENDING = "ADD_CITY_PENDING";
export const ADD_CITY_FAIL = "ADD_CITY_FAIL";
//Update City
export const UPDATE_CITY_SUCCESS = "UPDATE_CITY_SUCCESS";
export const UPDATE_CITY_PENDING = "UPDATE_CITY_PENDING";
export const UPDATE_CITY_FAIL = "UPDATE_CITY_FAIL";

//Country
export const COUNTRY_SUCCESS = "COUNTRY_SUCCESS";
export const COUNTRY_PENDING = "COUNTRY_PENDING";
export const COUNTRY_FAIL = "COUNTRY_FAIL";

//Role
export const ROLE_SUCCESS = "ROLE_SUCCESS";
export const ROLE_PENDING = "ROLE_PENDING";
export const ROLE_FAIL = "ROLE_FAIL";
//Add Role
export const ADD_ROLE_SUCCESS = "ADD_ROLE_SUCCESS";
export const ADD_ROLE_PENDING = "ADD_ROLE_PENDING";
export const ADD_ROLE_FAIL = "ADD_ROLE_FAIL";
//Update Role
export const UPDATE_ROLE_SUCCESS = "UPDATE_ROLE_SUCCESS";
export const UPDATE_ROLE_PENDING = "UPDATE_ROLE_PENDING";
export const UPDATE_ROLE_FAIL = "UPDATE_ROLE_FAIL";

//DoctorWithCustomer
export const DOCTOR_WITH_CUSTOMER_SUCCESS = "DOCTOR_WITH_CUSTOMER_SUCCESS";
export const DOCTOR_WITH_CUSTOMER_PENDING = "DOCTOR_WITH_CUSTOMER_PENDING";
export const DOCTOR_WITH_CUSTOMER_FAIL = "DOCTOR_WITH_CUSTOMER_FAIL";

//CustomerRank
export const CUSTOMER_RANK_SUCCESS = "CUSTOMER_RANK_SUCCESS";
export const CUSTOMER_RANK_PENDING = "CUSTOMER_RANK_PENDING";
export const CUSTOMER_RANK_FAIL = "CUSTOMER_RANK_FAIL";


//Add CustomerRank
export const ADD_CUSTOMER_RANK_SUCCESS = "ADD_CUSTOMER_RANK_SUCCESS";
export const ADD_CUSTOMER_RANK_PENDING = "ADD_CUSTOMER_RANK_PENDING";
export const ADD_CUSTOMER_RANK_FAIL = "ADD_CUSTOMER_RANK_FAIL";

//Update CustomerRank
export const UPDATE_CUSTOMER_RANK_SUCCESS = "UPDATE_CUSTOMER_RANK_SUCCESS";
export const UPDATE_CUSTOMER_RANK_PENDING = "UPDATE_CUSTOMER_RANK_PENDING";
export const UPDATE_CUSTOMER_RANK_FAIL = "UPDATE_CUSTOMER_RANK_FAIL";


//Doctor
export const DOCTOR_SUCCESS = "DOCTOR_SUCCESS";
export const DOCTOR_PENDING = "DOCTOR_PENDING";
export const DOCTOR_FAIL = "DOCTOR_FAIL";
//Add Doctor
export const ADD_DOCTOR_SUCCESS = "ADD_DOCTOR_SUCCESS";
export const ADD_DOCTOR_PENDING = "ADD_DOCTOR_PENDING";
export const ADD_DOCTOR_FAIL = "ADD_DOCTOR_FAIL";
//Update Doctor
export const UPDATE_DOCTOR_SUCCESS = "UPDATE_DOCTOR_SUCCESS";
export const UPDATE_DOCTOR_PENDING = "UPDATE_DOCTOR_PENDING";
export const UPDATE_DOCTOR_FAIL = "UPDATE_DOCTOR_FAIL";

//Doctor specialization
export const DOCTOR_SPECIALIZATION_SUCCESS = "DOCTOR_SPECIALIZATION_SUCCESS";
export const DOCTOR_SPECIALIZATION_PENDING = "DOCTOR_SPECIALIZATION_PENDING";
export const DOCTOR_SPECIALIZATION_FAIL = "DOCTOR_SPECIALIZATION_FAIL";
//Add Doctor specialization
export const ADD_DOCTOR_SPECIALIZATION_SUCCESS =
  "ADD_DOCTOR_SPECIALIZATION_SUCCESS";
export const ADD_DOCTOR_SPECIALIZATION_PENDING =
  "ADD_DOCTOR_SPECIALIZATION_PENDING";
export const ADD_DOCTOR_SPECIALIZATION_FAIL = "ADD_DOCTOR_SPECIALIZATION_FAIL";
//Update Doctor specialization
export const UPDATE_DOCTOR_SPECIALIZATION_SUCCESS =
  "UPDATE_DOCTOR_SPECIALIZATION_SUCCESS";
export const UPDATE_DOCTOR_SPECIALIZATION_PENDING =
  "UPDATE_DOCTOR_SPECIALIZATION_PENDING";
export const UPDATE_DOCTOR_SPECIALIZATION_FAIL =
  "UPDATE_DOCTOR_SPECIALIZATION_FAIL";

//Doctor RANK
export const DOCTOR_RANK_SUCCESS = "DOCTOR_RANK_SUCCESS";
export const DOCTOR_RANK_PENDING = "DOCTOR_RANK_PENDING";
export const DOCTOR_RANK_FAIL = "DOCTOR_RANK_FAIL";
//Add Doctor RANK
export const ADD_DOCTOR_RANK_SUCCESS = "ADD_DOCTOR_RANK_SUCCESS";
export const ADD_DOCTOR_RANK_PENDING = "ADD_DOCTOR_RANK_PENDING";
export const ADD_DOCTOR_RANK_FAIL = "ADD_DOCTOR_RANK_FAIL";
//Update Doctor RANK
export const UPDATE_DOCTOR_RANK_SUCCESS = "UPDATE_DOCTOR_RANK_SUCCESS";
export const UPDATE_DOCTOR_RANK_PENDING = "UPDATE_DOCTOR_RANK_PENDING";
export const UPDATE_DOCTOR_RANK_FAIL = "UPDATE_DOCTOR_RANK_FAIL";

//Practice Location
export const PRACTICE_LOCATION_SUCCESS = "PRACTICE_LOCATION_SUCCESS";
export const PRACTICE_LOCATION_PENDING = "PRACTICE_LOCATION_PENDING";
export const PRACTICE_LOCATION_FAIL = "PRACTICE_LOCATION_FAIL";

// Practive Location Type
export const PRACTICE_LOCATION_TYPE_SUCCESS = "PRACTICE_LOCATION_TYPE_SUCCESS";
export const PRACTICE_LOCATION_TYPE_PENDING = "PRACTICE_LOCATION_TYPE_PENDING";
export const PRACTICE_LOCATION_TYPE_FAIL = "PRACTICE_LOCATION_TYPE_FAIL";

//Add Practice Location
export const ADD_PRACTICE_LOCATION_SUCCESS = "ADD_PRACTICE_LOCATION_SUCCESS";
export const ADD_PRACTICE_LOCATION_PENDING = "ADD_PRACTICE_LOCATION_PENDING";
export const ADD_PRACTICE_LOCATION_FAIL = "ADD_PRACTICE_LOCATION_FAIL";
//Update Practice Location
export const UPDATE_PRACTICE_LOCATION_SUCCESS =
  "UPDATE_PRACTICE_LOCATION_SUCCESS";
export const UPDATE_PRACTICE_LOCATION_PENDING =
  "UPDATE_PRACTICE_LOCATION_PENDING";
export const UPDATE_PRACTICE_LOCATION_FAIL = "UPDATE_PRACTICE_LOCATION_FAIL";
//Location Types
export const LOCATION_TYPE_SUCCESS = "LOCATION_TYPE_SUCCESS";
export const LOCATION_TYPE_PENDING = "LOCATION_TYPE_PENDING";
export const LOCATION_TYPE_FAIL = "LOCATION_TYPE_FAIL";
//Add Location Types
export const ADD_LOCATION_TYPE_SUCCESS = "ADD_LOCATION_TYPE_SUCCESS";
export const ADD_LOCATION_TYPE_PENDING = "ADD_LOCATION_TYPE_PENDING";
export const ADD_LOCATION_TYPE_FAIL = "ADD_LOCATION_TYPE_FAIL";
//Update Location Types
export const UPDATE_LOCATION_TYPE_SUCCESS = "UPDATE_LOCATION_TYPE_SUCCESS";
export const UPDATE_LOCATION_TYPE_PENDING = "UPDATE_LOCATION_TYPE_PENDING";
export const UPDATE_LOCATION_TYPE_FAIL = "UPDATE_LOCATION_TYPE_FAIL";

//Store
export const STORE_SUCCESS = "STORE_SUCCESS";
export const STORE_PENDING = "STORE_PENDING";
export const STORE_FAIL = "STORE_FAIL";

//Store add
export const ADD_STORE_SUCCESS = "ADD_STORE_SUCCESS";
export const ADD_STORE_PENDING = "ADD_STORE_PENDING";
export const ADD_STORE_FAIL = "ADD_STORE_FAIL";

//Store update
export const UPDATE_STORE_SUCCESS = "UPDATE_STORE_SUCCESS";
export const UPDATE_STORE_PENDING = "UPDATE_STORE_PENDING";
export const UPDATE_STORE_FAIL = "UPDATE_STORE_FAIL";

//Store Type
export const STORE_TYPE_SUCCESS = "STORE_TYPE_SUCCESS";
export const STORE_TYPE_PENDING = "STORE_TYPE_PENDING";
export const STORE_TYPE_FAIL = "STORE_TYPE_FAIL";

//Store Type add
export const ADD_STORE_TYPE_SUCCESS = "ADD_STORE_TYPE_SUCCESS";
export const ADD_STORE_TYPE_PENDING = "ADD_STORE_TYPE_PENDING";
export const ADD_STORE_TYPE_FAIL = "ADD_STORE_TYPE_FAIL";

//Store Type update
export const UPDATE_STORE_TYPE_SUCCESS = "UPDATE_STORE_TYPE_SUCCESS";
export const UPDATE_STORE_TYPE_PENDING = "UPDATE_STORE_TYPE_PENDING";
export const UPDATE_STORE_TYPE_FAIL = "UPDATE_STORE_TYPE_FAIL";

//Stock
export const STOCK_SUCCESS = "STOCK_SUCCESS";
export const STOCK_PENDING = "STOCK_PENDING";
export const STOCK_FAIL = "STOCK_FAIL";

//Stock add
export const ADD_STOCK_SUCCESS = "ADD_STOCK_SUCCESS";
export const ADD_STOCK_PENDING = "ADD_STOCK_PENDING";
export const ADD_STOCK_FAIL = "ADD_STOCK_FAIL";

//Stock update
export const UPDATE_STOCK_SUCCESS = "UPDATE_STOCK_SUCCESS";
export const UPDATE_STOCK_PENDING = "UPDATE_STOCK_PENDING";
export const UPDATE_STOCK_FAIL = "UPDATE_STOCK_FAIL";

//Unit Type
export const UNIT_TYPE_SUCCESS = "UNIT_TYPE_SUCCESS";
export const UNIT_TYPE_PENDING = "UNIT_TYPE_PENDING";
export const UNIT_TYPE_FAIL = "UNIT_TYPE_FAIL";

//Unit Type add
export const ADD_UNIT_TYPE_SUCCESS = "ADD_UNIT_TYPE_SUCCESS";
export const ADD_UNIT_TYPE_PENDING = "ADD_UNIT_TYPE_PENDING";
export const ADD_UNIT_TYPE_FAIL = "ADD_UNIT_TYPE_FAIL";

//Unit Type update
export const UPDATE_UNIT_TYPE_SUCCESS = "UPDATE_UNIT_TYPE_SUCCESS";
export const UPDATE_UNIT_TYPE_PENDING = "UPDATE_UNIT_TYPE_PENDING";
export const UPDATE_UNIT_TYPE_FAIL = "UPDATE_UNIT_TYPE_FAIL";

//Task Types

//Tasks
export const TASK_SUCCESS = "TASK_SUCCESS";
export const TASK_PENDING = "TASK_PENDING";
export const TASK_FAIL = "TASK_FAIL";


// Add TaskNotes

export const ADD_TASK_NOTE_SUCCESS = "ADD_TASK_NOTE_SUCCESS";
export const ADD_TASK_NOTE_PENDING = "ADD_TASK_NOTE_PENDING";
export const ADD_TASK_NOTE_FAIL = "ADD_TASK_NOTE_FAIL";

// Get SubOrdinates

export const SUB_ORDINATES_SUCCESS = "SUB_ORDINATES_SUCCESS";
export const SUB_ORDINATES_PENDING = "SUB_ORDINATES_PENDING";
export const SUB_ORDINATES_FAIL = "SUB_ORDINATES_FAIL";


// GetTask Notes

export const TASK_NOTE_SUCCESS = "TASK_NOTE_SUCCESS";
export const TASK_NOTE_PENDING = "TASK_NOTE_PENDING";
export const TASK_NOTE_FAIL = "TASK_NOTE_FAIL";

//Task Detail
export const TASK_DETAIL_SUCCESS = "TASK_DETAIL_SUCCESS";
export const TASK_DETAIL_PENDING = "TASK_DETAIL_PENDING";
export const TASK_DETAIL_FAIL = "TASK_DETAIL_FAIL";

//Tasks Doctors
export const TASK_DOCTOR_SUCCESS = "TASK_DOCTOR_SUCCESS";
export const TASK_DOCTOR_PENDING = "TASK_DOCTOR_PENDING";
export const TASK_DOCTOR_FAIL = "TASK_DOCTOR_FAIL";

//Tasks Practice Location
export const TASK_PRACTICE_LOCATION_SUCCESS = "TASK_PRACTICE_LOCATION_SUCCESS";
export const TASK_PRACTICE_LOCATION_PENDING = "TASK_PRACTICE_LOCATION_PENDING";
export const TASK_PRACTICE_LOCATION_FAIL = "TASK_PRACTICE_LOCATION_FAIL";

//Tasks Product
export const TASK_PRODUCT_SUCCESS = "TASK_PRODUCT_SUCCESS";
export const TASK_PRODUCT_PENDING = "TASK_PRODUCT_PENDING";
export const TASK_PRODUCT_FAIL = "TASK_PRODUCT_FAIL";

// Update Subtask 

export const UPDATE_SUBTASK_SUCCESS = "UPDATE_SUBTASK_SUCCESS";
export const UPDATE_SUBTASK_PENDING = "UPDATE_SUBTASK_PENDING";
export const UPDATE_SUBTASK_FAIL = "UPDATE_SUBTASK_FAIL";


//Tasks Sub Ordinates
export const TASK_SUB_ORDINATE_SUCCESS = "TASK_SUB_ORDINATE_SUCCESS";
export const TASK_SUB_ORDINATE_PENDING = "TASK_SUB_ORDINATE_PENDING";
export const TASK_SUB_ORDINATE_FAIL = "TASK_SUB_ORDINATE_FAIL";

//Tasks Sub Tasks
export const TASK_SUB_TASK_SUCCESS = "TASK_SUB_TASK_SUCCESS";
export const TASK_SUB_TASK_PENDING = "TASK_SUB_TASK_PENDING";
export const TASK_SUB_TASK_FAIL = "TASK_SUB_TASK_FAIL";

//Tasks priority
export const TASK_SECTION_PRIORITY_SUCCESS = "TASK_SECTION_PRIORITY_SUCCESS";
export const TASK_SECTION_PRIORITY_PENDING = "TASK_SECTION_PRIORITY_PENDING";
export const TASK_SECTION_PRIORITY_FAIL = "TASK_SECTION_PRIORITY_FAIL";

//Tasks status
export const TASK_SECTION_STATUS_SUCCESS = "TASK_SECTION_STATUS_SUCCESS";
export const TASK_SECTION_STATUS_PENDING = "TASK_SECTION_STATUS_PENDING";
export const TASK_SECTION_STATUS_FAIL = "TASK_SECTION_STATUS_FAIL";

//Tasks status
export const TASK_SECTION_TYPE_SUCCESS = "TASK_SECTION_TYPE_SUCCESS";
export const TASK_SECTION_TYPE_PENDING = "TASK_SECTION_TYPE_PENDING";
export const TASK_SECTION_TYPE_FAIL = "TASK_SECTION_TYPE_FAIL";

//Tasks city
export const TASK_CITY_SUCCESS = "TASK_CITY_SUCCESS";
export const TASK_CITY_PENDING = "TASK_CITY_PENDING";
export const TASK_CITY_FAIL = "TASK_CITY_FAIL";

//Add tasks
export const ADD_TASK_SUCCESS = "ADD_TASK_SUCCESS";
export const ADD_TASK_PENDING = "ADD_TASK_PENDING";
export const ADD_TASK_FAIL = "ADD_TASK_FAIL";

//Size of Page
export const SIZE_OF_PAGE = 10;
