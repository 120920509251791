import {
    DEPARTMENT_SUCCESS,
    DEPARTMENT_FAIL,
    SET_MESSAGE,
    DEPARTMENT_PENDING,
    ADD_DEPARTMENT_SUCCESS,
    ADD_DEPARTMENT_FAIL,
    ADD_DEPARTMENT_PENDING,
    UPDATE_DEPARTMENT_SUCCESS,
    UPDATE_DEPARTMENT_FAIL,
    UPDATE_DEPARTMENT_PENDING
} from "../types";
import { toast } from "react-toastify";
import UserService from "../../services/user.service";


export const getDepartments = (pageNumber, pageSize, searchQuery) => async(dispatch) => {
    dispatch({
        type: DEPARTMENT_PENDING,
        payload: { departments: "isLoading" },
    });
    return await UserService.getDepartments(pageNumber, pageSize, searchQuery).then(

        (data) => {
            data.data.status ?
                dispatch({
                    type: DEPARTMENT_SUCCESS,
                    payload: { departments: data.data },
                    
                }) :
                dispatch({
                    type: DEPARTMENT_FAIL,
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: DEPARTMENT_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        return Promise.reject();
    });
};

//add department
export const addDepartments = (body) => async(dispatch) => {
    dispatch({
        type: ADD_DEPARTMENT_PENDING,
        payload: { addDepartment: "isLoading" },
    });
    return await UserService.addDepartment(body).then(
        (data) => {
            data.data.status ? toast.success("Department added Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: ADD_DEPARTMENT_SUCCESS,
                    payload: { addDepartment : data.data.status },
                }) :
                dispatch({
                    type: ADD_DEPARTMENT_FAIL,
                    payload: { addDepartment : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: ADD_DEPARTMENT_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    }).finally(()=>{
        dispatch({
          type: ADD_DEPARTMENT_FAIL,
        });
      });
};

//update product
export const updateDepartments = (body) => async(dispatch) => {
    dispatch({
        type: UPDATE_DEPARTMENT_PENDING,
        payload: { updateDepartment: "isLoading" },
    });
    return await UserService.updateDepartment(body).then(
        (data) => {
            data.data.status ? toast.success("Department updated Successfully !", {autoClose: 2000 }): toast.error(data.data.message, {autoClose: 2000 });
            data.data.status ?
                dispatch({
                    type: UPDATE_DEPARTMENT_SUCCESS,
                    payload: { updateDepartment : data.data.status },
                }) :
                dispatch({
                    type: UPDATE_DEPARTMENT_FAIL,
                    payload: { updateDepartment : data.data.status },
                });
            return Promise.resolve();
        },
        (error) => {
            console.log("error......", error);
            const message =
                (error.response &&
                    error.response.data &&
                    error.response.data.message) ||
                error.message ||
                error.toString();

            dispatch({
                type: UPDATE_DEPARTMENT_FAIL,
            });

            dispatch({
                type: SET_MESSAGE,
                payload: message,
            });

            return Promise.reject();
        }
    ).catch((error) => {
        console.log(error)
    }).finally(()=>{
        dispatch({
          type: UPDATE_DEPARTMENT_FAIL,
        });
      });
};