import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { addEmploymentStatuses, updateEmploymentStatuses } from '../../actions/userActions/employmentStatusAction'
import Loader from '../subComponents/Loader'
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import TextField from "@mui/material/TextField";
import { SaveButton } from "../subComponents/buttons/SaveButton";
import { CancelButton } from "../subComponents/buttons/CancelButton";
function AddEmploymentStatuses() {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const initialValues = { name: "", description: "", active: false };
    const [formValues, setFormValues] = useState(initialValues);
    const [formErrors, setFormErrors] = useState({});
    useEffect(() => {
        if (location.employmentStatus) {
          setFormValues({
            ...formValues,
            name: location.employmentStatus.row.name,
            description: location.employmentStatus.row.description,
            id: location.id,
            active: location.employmentStatus.row.active,
          });
        }
      }, [location]);


      const addSubmitHandle = (e) => {
        e.preventDefault();
        const errors = validate(formValues);
        setFormErrors(errors);
    
        if (Object.keys(errors).length === 0) {
          if (location.employmentStatus) {
            dispatch(updateEmploymentStatuses(formValues));
          } else {
            dispatch(addEmploymentStatuses(formValues));
          }
        }
      };




    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormValues({ ...formValues, [name]: value });
        if (name === "active" && formValues.active === true) {
            setFormValues({ ...formValues, [name]: false });
        }
        else if (name === "active" && formValues.active === false) {
            setFormValues({ ...formValues, [name]: true });
        }
    }

    const validate = (values) => {
        const errors = {};
        if (!values.name) {
            errors.name = "Name is required!"
        }
        if (!values.description) {
            errors.description = "Description is required!"
        }
        return errors;
    }
    const addEmploymentStatus = useSelector(state => state.employmentStatuses_reducer.addEmploymentStatus);
    const updateEmploymentStatus = useSelector(state => state.employmentStatuses_reducer.updateEmploymentStatus);
    const permissions = JSON.parse(localStorage.getItem("permissionsList"));
    const handleGoBack = () => {
        history.goBack();
    };
    return (
        <>
            {addEmploymentStatus && addEmploymentStatus !== "isLoading" && <Redirect to="/admin/employmentStatuses" />}
            {updateEmploymentStatus && updateEmploymentStatus != "isLoading" && <Redirect to="/admin/employmentStatuses" />}
            {!location.employmentStatus && <Redirect to="/admin/AddEmploymentStatuses" />}
            {!permissions.includes("CanAddEmploymentStatus") && <Redirect to="/admin" />}
            {!permissions.includes("CanUpdateEmploymentStatus") && <Redirect to="/admin" />}
            <div>
                <div className="row">
                    <div className="col-md-6">
                        <h2 className="d-inine-block float-left">
                            <i
                                onClick={history.goBack}
                                className="fas fa-arrow-left"
                                aria-hidden="true"
                                style={{ cursor: "pointer" }}
                            />{" "}
                            {location.employmentStatus ? "Update Employment Status" : "Add Employment Status"}
                        </h2>
                    </div>
                    <hr
                        style={{
                            marginLeft: "14px",
                            backgroundColor: "rgba(0, 0, 0, 0.12)",
                            height: "1.3px",
                            marginTop: "15px",
                            width: "97%",
                        }}
                    />
                    <div className="col-md-6"></div>
                </div>
                <div className="row">
                    <div className="col-md-12 mt-5">
                        <TextField
                            style={{ width: "100%", fontSize: "46px" }}
                            className="first_textfield"
                            id="outlined-controlled"
                            label="Name *"
                            name="name"
                            value={formValues.name}
                            onChange={handleChange}
                        />
                        <div style={{ color: "red" }}>{formErrors.name}</div>
                    </div>

                    <div className="col-md-12 mt-5">
                        <TextField
                            style={{ width: "100%" }}
                            multiline
                            rows={3}
                            value={formValues.description}
                            name="description"
                            onChange={handleChange}
                            label="Description *"
                        />
                        <div style={{ color: "red" }}>{formErrors.description}</div>
                    </div>

                    <div className="col-md-12 mt-5">
                        <div className="form-group" style={{ display: "flex", alignItems: "flex-start" }}>
                            <input
                                name="active"
                                style={{ cursor: "pointer", height: "16px", width: "16px" }}
                                type="checkbox"
                                checked={formValues.active}
                                className="ml-1 mt-1"
                                onChange={handleChange}
                            />

                            <label
                                style={{ paddingLeft: "10px", }}
                                className="form-control-label"

                            >
                                Is this status active?
                            </label>
                        </div>
                    </div>
                    <div className="col-lg-12" style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
                        <CancelButton
                            onClick={handleGoBack}
                        />
                        <SaveButton
                            onClick={addSubmitHandle}
                            isLoading={addEmploymentStatus === "isLoading" || updateEmploymentStatus === "isLoading"}
                            text={location.employmentStatus ? "Update" : "Save"}
                        />
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddEmploymentStatuses
