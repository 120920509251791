import {
    CALL_AVERAGE_PRO_SUCCESS,
    CALL_AVERAGE_PRO_FAIL,
    CALL_AVERAGE_PRO_PENDING,
  } from "../../actions/types";
  
  const initialState = {
    callAvgPro: [],
  };
  
  const call_avg_pro_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case CALL_AVERAGE_PRO_SUCCESS:
        return {
          ...state,
          callAvgPro: payload.callAvgPro,
        };
      case CALL_AVERAGE_PRO_PENDING:
        return {
          ...state,
          callAvgPro: payload.callAvgPro,
        };
      case CALL_AVERAGE_PRO_FAIL:
        return {
          ...state,
          callAvgPro: [],
        };
      default:
        return state;
    }
  };
  export default call_avg_pro_reducer;
  