import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getStores } from '../../actions/userActions/storeAction'
import InnerHeader from '../InnerHeader/InnderHeader'
import AUModal from './AUStoreModal'
import TableComponent from '../subComponents/table/TableComponent'
import { CircularProgress } from '@mui/material'
import StoreBlueIcon from '../../assets/images/store_blue_icon.svg'
import StoreTypeBlueIcon from '../../assets/images/store_type_blue_icon.svg'
import LocationBlueIcon from '../../assets/images/location_blue_icon.svg'
import EmployeeBlueIcon from '../../assets/images/employee_icon.svg'
import CallBlueIcon from '../../assets/images/call_blue_icon.svg'
import StatusblueIcon from '../../assets/images/status_blue_icon.svg'
import ViewModal from '../subComponents/modals/ViewModal'
import DeleteModal from '../subComponents/modals/DeleteModal'
import Select from 'react-select'
import { getStoreTypes } from '../../actions/userActions/storeTypeAction'
function Stores() {
  const [store, setStore] = useState()

  const [openAdd, setOpenAdd] = useState(false)
  const handleOpenAdd = () => setOpenAdd(true)
  const handleCloseAdd = () => setOpenAdd(false)

  const [openEdit, setOpenEdit] = useState(false)
  const handleOpenEdit = () => setOpenEdit(true)
  const handleCloseEdit = () => {
    setOpenEdit(false)
    setStore(null)
  }

  const [openView, setOpenView] = useState(false)
  const handleOpenView = () => {
    setOpenView(true)
  }
  const handleCloseView = () => {
    setOpenView(false)
    setStore(null)
  }
  const [page, setPage] = useState(1)

  const [Filters, setFilters] = useState({
    isActive: null,
    textSearch: '',
    type: [],
    state: [],
    city: [],
    area: [],
    block: [],
  })
  const [selected, setSelected] = useState({
    textSearch: '',
    isActive: true,
  })
  const body = {
    filter: Filters,
    pageNumber: page,
    pageSize: 10,
  }

  // store type body
  const storeTypesBody = {
    "filter": {
      "isActive": true,
      "textSearch": ""
    },
    "pageNumber": -1,
    "pageSize": 0
  }
  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 4) {
      setTimeout(() => {
        setFilters({ ...Filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...Filters, ['textSearch']: e.target.value })
    }
  }

  const getOptions = (data, name, activecheck) => {
    console.log(data,"data");
    const getData =
      data && data !== 'isLoading'
        ? !activecheck
          ? data.map((row, i) => ({
              value: row.id,
              label: row.name
                ? row.name
                : row.firstName && row.lastName
                ? `${row.firstName} ${row.lastName}`
                : row.address,
              name: name,
            }))
          : data
              .filter((row) => row.active === true)
              .map((row, i) => ({
                value: row.id,
                label: row.name
                  ? row.name
                  : row.firstName && row.lastName
                  ? `${row.firstName} ${row.lastName}`
                  : row.address,
                name: name,
              }))
        : []

    return getData
  }


  const handleChange = (e, name) => {
    if (name == 'reset') {
      setSelected({
        ...Filters,

        textSearch: '',
        type: [],

      })
      setFilters({
        ...Filters,
        textSearch: '',
        type: [],
        
      })
    } else {
      setFilters({ ...Filters, [name]: e.map((row, i) => row.value) })
      setSelected({
        ...selected,
        [name]: e.map((row, i) => ({
          value: row.value,
          label: row.label,
          name: row.name,
        })),
      })
    }
  }

  const [openDelete, setOpenDelete] = useState(false)
  const handleOpenDelete = () => setOpenDelete(true)
  const handleCloseDelete = () => setOpenDelete(false)

  const dispatch = useDispatch()
  const addStores = useSelector((state) => state.stores_reducer.addStore)
  const updateStores = useSelector((state) => state.stores_reducer.updateStore)

  useEffect(() => {
    dispatch(getStores(body))
    dispatch(getStoreTypes(storeTypesBody))
  }, [addStores, updateStores, page, Filters])

  const storeTypes = useSelector((state) => state.store_types_reducer.store_types)
  console.log(storeTypes, "allStoreTypes");

  const stores = useSelector((state) => state.stores_reducer.stores)
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const [query, setQuery] = useState('')

  //   const filter_stores =
  //     stores && stores !== 'isLoading'
  //       ? stores.filter((s) => {
  //           return s.name.toLowerCase().includes(query.toLowerCase())
  //         })
  //       : []

  return (
    <>
      <div className="container mt-5">
        <InnerHeader
          title="Stores"
          description="Manage all your stores or add new one"
          handleSearch={handleSearch}
          value={selected.textSearch}
          serverSideSearch={true}
          searchPlaceholder="Search store"
          permission={permissions.includes('CanAddStore')}
          buttonTitle="Add Store"
          onClick={handleOpenAdd}
        />


        <div className='row mt-4'>
          <div className='col-7'></div>
          <div className='col-3 mt-1' >
            {storeTypes!=="isLoading" && storeTypes && 
            <Select
            value={selected.type}
              isSearchable
              isMulti
              options={getOptions(storeTypes.storeTypes,"storeTypes", true)}
              name='storeType'
              className="basic-multi-select"
              classNamePrefix="select"
              placeholder="Select Store Type"
              onChange={(e) => handleChange(e, 'type')}>
        </Select>
            }
            
         </div>
         <div className="col-2 ">
         

            <button
              className="btn btn-primary btn-sm d-block m-auto task-filter-reset-btn mt-1"
              onClick={(e) => handleChange(e, 'reset')}
            >
              {' '}
              Reset filter
            </button>
          </div>
        </div>
        

        <div className="row">
          <div className="col-md-12">
            {stores === 'isLoading' && (
              <div style={{ height: '70vh' }}>
                <div className="d-flex my-5" style={{ height: '100%' }}>
                  <CircularProgress
                    sx={{ margin: 'auto', color: 'rgba(0, 45, 114, 1)' }}
                  />
                </div>
              </div>
            )}
            {stores && stores !== 'isLoading' && (
              <TableComponent
                data={stores.stores}
                headers={['Store Name', 'Store Type', 'Status']}
                keys={['name', 'typeName', 'active']}
                descriptionKeys={['block', 'area', 'city', 'state']}
                setData={setStore}
                doubleTitle={true}
                onEyeClick={handleOpenView}
                onEditClick={handleOpenEdit}
                onDeleteClick={handleOpenDelete}
              />
            )}
          </div>
        </div>
      </div>
      <DeleteModal
        title="Delete Store "
        type="store"
        open={openDelete}
        onClose={handleCloseDelete}
      />
      {store && (
        <ViewModal
          title="Store"
          headings={[
            'Store Name',
            'Store Type',
            'State',
            'City',
            'Area',
            'Block',
            'Contact Name',
            'Contact Number',
            'Status',
          ]}
          keys={[
            'name',
            'typeName',
            'state',
            'city',
            'area',
            'block',
            'contactName',
            'contactNumber',
            'active',
          ]}
          icons={[
            StoreBlueIcon,
            StoreTypeBlueIcon,
            LocationBlueIcon,
            LocationBlueIcon,
            LocationBlueIcon,
            LocationBlueIcon,
            EmployeeBlueIcon,
            CallBlueIcon,
            StatusblueIcon,
          ]}
          data={store}
          open={openView}
          onClose={handleCloseView}
        />
      )}
      <AUModal
        type="add"
        onViewClose={handleCloseView}
        open={openAdd}
        onClose={handleCloseAdd}
      />
      {store && (
        <AUModal
          type="update"
          onViewClose={handleCloseView}
          store={store}
          open={openEdit}
          onClose={handleCloseEdit}
        />
      )}
    </>
  )
}

export default Stores
