import {
    CALL_AVERAGE_PRACT_SUCCESS,
    CALL_AVERAGE_PRACT_FAIL,
    CALL_AVERAGE_PRACT_PENDING,
  } from "../../actions/types";
  
  const initialState = {
    callAvgPract: [],
  };
  
  const call_avg_pract_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
      case CALL_AVERAGE_PRACT_SUCCESS:
        return {
          ...state,
          callAvgPract: payload.callAvgPract,
        };
      case CALL_AVERAGE_PRACT_PENDING:
        return {
          ...state,
          callAvgPract: payload.callAvgPract,
        };
      case CALL_AVERAGE_PRACT_FAIL:
        return {
          ...state,
          callAvgPract: [],
        };
      default:
        return state;
    }
  };
  export default call_avg_pract_reducer;
  