import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { MODAL_STYLE } from '../../Constatns'
import { addStore, updateStore } from '../../actions/userActions/storeAction'
import CrossIcon from '../../assets/images/cross_icon.svg'
import { nullUpdateStore } from '../../actions/userActions/storeAction'
import { getStoreTypes } from '../../actions/userActions/storeTypeAction'
import CircularProgress from '@mui/material/CircularProgress'
import {
  Box,
  FormControlLabel,
  Checkbox,
  Typography,
  Modal,
  Button,
  TextField,
  Autocomplete,
} from '@mui/material'

function AUStoreModal(props) {
  const dispatch = useDispatch()
  const initialValues = {
    name: '',
    type: '',
    state: '',
    city: '',
    area: '',
    contactName: '',
    contactNumber: '',
    active: false,
  }

  const [page, setPage] = useState(1)

  const [Filters, setFilters] = useState({
    textSearch: '',
    isActive: true,
  })

  const body = {
    filter: Filters,
    pageNumber: page,
    pageSize: 100,
  }

  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})
  const [isSubmit, setIsSubmit] = useState(false)
  const resetValues = () => {
    setFormErrors({})
    setFormValues(initialValues)
    setIsSubmit(false)
  }
  useEffect(() => {
    dispatch(getStoreTypes(body))
    {
      props.type == 'update' &&
        props.store &&
        setFormValues({
          ...formValues,
          ['name']: props.store.name,
          ['type']: props.store.type,
          ['state']: props.store.state,
          ['city']: props.store.city,
          ['area']: props.store.area,
          ['block']: props.store.block,
          ['contactName']: props.store.contactName,
          ['contactNumber']: props.store.contactNumber,
          ['active']: props.store.active,
          ['id']: props.store.id,
        })
    }
  }, [])

  const hanleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
  }

  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Store name is required!'
    }
    if (
      values.contactNumber &&
      !/^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i.test(
        values.contactNumber,
      )
    ) {
      errors.contactNumber = 'Please enter valid contact number!'
    }
    if (values.type == '' || values.type == null) {
      errors.type = 'Store type is required!'
    }
    if (values.state === '') {
      errors.state = 'State is required!'
    }
    if (values.city === '') {
      errors.city = 'City is required!'
    }
    if (values.area === '') {
      errors.area = 'Area is required!'
    }


    if (Object.keys(errors).length === 0) {
      setIsSubmit(true)
    } else {
      setIsSubmit(false)
    }
    return errors
  }
  const allStoreTypes = useSelector(
    (state) => state.store_types_reducer.store_types,
  )
  const addStores = useSelector((state) => state.stores_reducer.addStore)
  const updateStores = useSelector((state) => state.stores_reducer.updateStore)
  console.log(allStoreTypes)
  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      if (props.type == 'add') {
        dispatch(addStore(formValues))
      }
      if (props.type == 'update') {
        dispatch(updateStore(formValues))
      }
    }
  }, [formErrors])
  useEffect(() => {
    if (addStores && addStores !== 'isLoading') {
      if (props.type == 'add') {
        props.onClose()
        resetValues()
        setIsSubmit(false)
      }
    }
  }, [addStores])

  useEffect(() => {
    if (updateStores && updateStores != 'isLoading') {
      if (props.type == 'update') {
        props.onClose()
        setIsSubmit(false)
        dispatch(nullUpdateStore())
      }
    }
  }, [updateStores])

  const submitHandle = async (e) => {
    setFormErrors(validate(formValues))
  }

  return (
    <>
      <Modal
        open={props.open}
        onClose={() => {
          resetValues()
          props.onClose()
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={MODAL_STYLE}>
          <Typography
            sx={{ marginBottom: '30px !important', fontWeight: '600' }}
            className="text-center mb-5"
            id="modal-modal-title"
            variant="h5"
            component="h2"
          >
            {props.type == 'add' && 'Add Store'}{' '}
            {props.type == 'update' && 'Edit Store'}{' '}
            <img
              onClick={() => {
                resetValues()
                props.onClose()
              }}
              style={{
                position: 'absolute',
                top: '35px',
                right: '30px',
                height: '30px',
                cursor: 'pointer',
              }}
              src={CrossIcon}
              alt=""
            />
          </Typography>
          {allStoreTypes && allStoreTypes !== 'isLoading' ? (
            <div>
              <div className="row mt-2">
                <div className="col-6 pr-2">
                  <TextField
                    name="name"
                    onChange={hanleChange}
                    value={formValues.name}
                    size="small"
                    className="form-control"
                    id="outlined-basic"
                    label="Store Name"
                    variant="outlined"
                    required
                    error={formErrors.name}
                    helperText={formErrors.name}
                  />
                </div>
                <div className="col-6 pl-2">
                  <Autocomplete
                    id="grouped-demo"
                    name="type"
                    size="small"
                    value={
                      allStoreTypes.storeTypes.find(
                        (storetype) => storetype.id == formValues.type,
                      )?.active
                        ? allStoreTypes.storeTypes.find(
                            (storetype) => storetype.id == formValues.type,
                          )
                        : null
                    }
                    onChange={(e, newVal) => {
                      setFormValues({
                        ...formValues,
                        ['type']: newVal ? newVal.id : null,
                      })
                    }}
                    options={allStoreTypes.storeTypes.filter((e) => e.active)}
                    //   groupBy={(option) => option.firstLetter}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <TextField
                        error={formErrors.type}
                        helperText={formErrors.type}
                        {...params}
                        label="Store Type"
                        name="type"
                      />
                    )}
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6 pr-2">
                  <TextField
                    error={formErrors.state}
                    helperText={formErrors.state}
                    name="state"
                    value={formValues.state}
                    onChange={hanleChange}
                    size="small"
                    className="form-control"
                    id="outlined-basic"
                    label="State"
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-6 pl-2">
                  <TextField
                    name="city"
                    error={formErrors.city}
                    helperText={formErrors.city}
                    value={formValues.city}
                    onChange={hanleChange}
                    size="small"
                    className="form-control"
                    id="outlined-basic"
                    label="City"
                    variant="outlined"
                    required
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6 pr-2">
                  <TextField
                    name="area"
                    error={formErrors.area}
                    helperText={formErrors.area}
                    value={formValues.area}
                    onChange={hanleChange}
                    size="small"
                    className="form-control"
                    id="outlined-basic"
                    label="Area"
                    variant="outlined"
                    required
                  />
                </div>
                <div className="col-6 pl-2">
                  <TextField
                    name="block"
                    onChange={hanleChange}
                    value={formValues.block}
                    size="small"
                    className="form-control"
                    id="outlined-basic"
                    label="Block"
                    variant="outlined"
                  />
                </div>
              </div>
              <div className="row mt-4">
                <div className="col-6 pr-2">
                  <TextField
                    name="contactName"
                    onChange={hanleChange}
                    value={formValues.contactName}
                    size="small"
                    className="form-control"
                    id="outlined-basic"
                    label="Contact Name"
                    variant="outlined"
                  />
                </div>
                <div className="col-6 pl-2">
                  <TextField
                    name="contactNumber"
                    error={formErrors.contactNumber}
                    helperText={formErrors.contactNumber}
                    value={formValues.contactNumber}
                    onChange={hanleChange}
                    size="small"
                    className="form-control"
                    id="outlined-basic"
                    label="Contact Number"
                    variant="outlined"
                  />
                </div>
              </div>
              {props.type == 'update' && (
                <div className="row mt-4">
                  <div className="col-12">
                    <FormControlLabel
                      sx={{ fontSize: '14px' }}
                      control={
                        <Checkbox
                          onChange={() => {
                            setFormValues({
                              ...formValues,
                              ['active']: !formValues.active,
                            })
                          }}
                          className="pl-3 pr-2"
                          defaultChecked={formValues.active}
                        />
                      }
                      label="Active"
                    />
                  </div>
                </div>
              )}
          {props.type === 'add' && (
    <div className="row mt-4">
        <div className="col-12">
            <FormControlLabel
                sx={{ fontSize: '14px' }}
                control={
                    <Checkbox
                        onChange={() => {
                            setFormValues({
                                ...formValues,
                                active: !formValues.active,
                            });
                        }}
                        className="pl-3 pr-2"
                        checked={formValues.active}
                    />
                }
                label="Active"
            />
        </div>
    </div>
)}



              <div className="row">
                <div className="col-12">
                  <Button
                    sx={{ marginTop: '30px', fontWeight: '600' }}
                    onClick={submitHandle}
                    type="submit"
                    name="submit"
                    className=" btn btn-primary text-white w-100 text-capitalize"
                    disabled={isSubmit && formErrors != {}}
                  >
                    {addStores === 'isLoading' ||
                    updateStores === 'isLoading' ? (
                      <CircularProgress sx={{ color: 'white' }} />
                    ) : props.type == 'add' ? (
                      'Add'
                    ) : (
                      'Update'
                    )}
                  </Button>
                </div>
              </div>
            </div>
          ) : (
            <div className="d-flex">
              <CircularProgress
                sx={{ color: 'rgba(0, 45, 114, 1)', margin: 'auto' }}
              />
            </div>
          )}
        </Box>
      </Modal>
    </>
  )
}

export default AUStoreModal
