import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import {
  addDesignations,
  updateDesignations,
} from '../../actions/userActions/designationAction'
import Loader from '../subComponents/Loader'
import { Redirect, useLocation, useHistory } from 'react-router-dom'
import TextField from "@mui/material/TextField";
import { SaveButton } from "../subComponents/buttons/SaveButton";
import { CancelButton } from "../subComponents/buttons/CancelButton";
function AddDesignations() {
  const dispatch = useDispatch()
  const location = useLocation()
  const history = useHistory()
  const initialValues = {
    name: '',
    shortName: '',
    description: '',
    active: false,
  }
  const [formValues, setFormValues] = useState(initialValues)
  const [formErrors, setFormErrors] = useState({})

  useEffect(() => {
    if (location.designation) {
      setFormValues({
        ...formValues,
        ['name']: location.designation.row.name,
        ['shortName']: location.designation.row.shortName,
        ['description']: location.designation.row.description,
        ['id']: location.id,
        ['active']: location.designation.row.active,
      })
    }
  }, [])

  const addDesignationHandle = (e) => {
    e.preventDefault()
    const errors = validate(formValues)
    setFormErrors(errors)

    if (Object.keys(errors).length === 0) {
      if (location.designation) {
        dispatch(updateDesignations(formValues))
      } else {
        dispatch(addDesignations(formValues))
      }
    }
  }

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormValues({ ...formValues, [name]: value })
    if (
      (name === 'active' && formValues.active === true) ||
      formValues.active === 'on'
    ) {
      setFormValues({ ...formValues, [name]: false })
    } else if (name === 'active' && formValues.active === false) {
      setFormValues({ ...formValues, [name]: true })
    }
  }

  const validate = (values) => {
    const errors = {}
    if (!values.name) {
      errors.name = 'Name is required!'
    }
    if (!values.shortName) {
      errors.shortName = 'Short name is required!'
    }
    if (!values.description) {
      errors.description = 'Description is required!'
    }
    return errors
  }
  const addDesignation = useSelector(
    (state) => state.designations_reducer.addDesignation,
  )
  const updateDesignation = useSelector(
    (state) => state.designations_reducer.updateDesignation,
  )
  const permissions = JSON.parse(localStorage.getItem('permissionsList'));
  const handleGoBack = () => {
    history.goBack();
  };
  return (
    <>
      {addDesignation && addDesignation !== 'isLoading' && (
        <Redirect to="/admin/designations" />
      )}
      {updateDesignation && updateDesignation != "isLoading" && <Redirect to="/admin/designations" />}
      {!location.designation && <Redirect to="/admin/AddDesignations" />}
      {!permissions.includes('CanAddDesignation') && <Redirect to="/admin" />}
      {!permissions.includes('CanUpdateDesignation') && (
        <Redirect to="/admin" />
      )}
      <div>
        <div className="row">
          <div className="col-md-6">
            <h2 className="d-inine-block float-left">
              <i
                onClick={history.goBack}
                className="fas fa-arrow-left"
                aria-hidden="true"
                style={{ cursor: "pointer" }}
              />{" "}
              {location.designation ? "Update Designation" : "Add Designation"}
            </h2>
          </div>
          <hr
            style={{
              marginLeft: "14px",
              backgroundColor: "rgba(0, 0, 0, 0.12)",
              height: "1.3px",
              marginTop: "15px",
              width: "97%",
            }}
          />
          <div className="col-md-6"></div>
        </div>
        <div className="row">
          <div className='col-6 mt-5'>
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Name *"
              name="name"
              value={formValues.name}
              onChange={handleChange}
            />
            <div style={{ color: "red" }}>{formErrors.name}</div>
          </div>
          <div className='col-6 mt-5'>
            <TextField
              style={{ width: "100%", fontSize: "46px" }}
              className="first_textfield"
              id="outlined-controlled"
              label="Short Name *"
              name="shortName"
              value={formValues.shortName}
              onChange={handleChange}
            />
            <div style={{ color: "red" }}>{formErrors.shortName}</div>
          </div>
          <div className='col mt-5'>
            <TextField
              style={{ width: "100%" }}
              multiline
              rows={3}
              value={formValues.description}
              name="description"
              onChange={handleChange}
              label="Description *"
            />
            <div style={{ color: "red" }}>{formErrors.description}</div>
          </div>

          <div className="form-group mt-5" style={{ display: "flex", alignItems: "flex-start" }}>
            <input
              name="active"
              style={{ cursor: "pointer", height: "16px", width: "16px" }}
              type="checkbox"
              checked={formValues.active}
              className="ml-1 mt-1"
              onChange={handleChange}
            />

            <label
              style={{ paddingLeft: "10px", }}
              className="form-control-label"

            >
              Is this designation active?
            </label>
          </div>
          <div className="col-lg-12" style={{ display: "flex", justifyContent: "flex-end", gap: "20px" }}>
            <CancelButton
              onClick={handleGoBack}
            />
            <SaveButton
              onClick={addDesignationHandle}
              isLoading={addDesignation === "isLoading" || updateDesignation === "isLoading"}
              text={location.designation ? "Update" : "Save"}
            />
          </div>
        </div>
      </div >
    </>
  )
}

export default AddDesignations
