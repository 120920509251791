import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'
import { getCities } from '../../actions/userActions/cityAction'
import { getPracticeLocations } from '../../actions/userActions/practiceLocationAction'
import Loader from '../subComponents/Loader'
import Search from '../subComponents/search/Search'
import Pagination from '../paginatioin/pagination'
import AddButton from '../subComponents/buttons/AddButton'
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { IconButton, Tooltip } from "@mui/material";
import ServerSideSearch from '../subComponents/search/ServerSideSearch'
import EditButton from '../subComponents/buttons/EditButton'
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

function PracticeLocations() {
  const dispatch = useDispatch()
  const history = useHistory()
  const [selectedCity, setSelectedCity] = useState(1);
  const [isExpanded, setIsExpanded] = useState(false);


  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const [page, setPage] = useState(1)
  const [cityPage, setCityPage] = useState(1)
  const [selected, setSelected] = useState({
    textSearch: '',
    city: '',
  })
  const [filters, setFilters] = useState({
    textSearch: '',
    city: selectedCity,
  })
  const [cityFilters, setCityFilters] = useState({
    textSearch: '',
    countryId: '1',
  })

  const body = {
    filter: filters,
    pageNumber: page,
    pageSize: 10,
  }
  const cityBody = {
    filter: cityFilters,
    pageNumber: -1,
    pageSize: 0,
  }
  useEffect(() => {
    dispatch(getCities(cityBody))
      .then(() => {
        dispatch(getPracticeLocations(body))
      })
  }, [page, filters])

  const practiceLocations = useSelector(
    (state) => state.practiceLocations_reducer.practiceLocations
  )
  const allCities = useSelector((state) => state.cities_reducer.cities)
  const permissions = JSON.parse(localStorage.getItem('permissionsList'))
  const getPaginationNo = (pageNum) => {
    const object = []
    if (practiceLocations !== 'isLoading' && practiceLocations.length !== 0) {
      for (let i = 0; i < practiceLocations[0].page.totalPages; i++) {
        object.push(
          <button
            className={pageNum == i + 1 ? 'active' : ''}
            onClick={() => setPage(i + 1)}
          >
            {i + 1}
          </button>,
        )
      }
    }
    return object
  }
  // useEffect(() => {
  //   dispatch(getPracticeLocations(body))
  // }, [page, filters])
  const handleSearch = (e) => {
    setSelected({ ...selected, ['textSearch']: e.target.value })
    if (e.target.value.length >= 1) {
      setTimeout(() => {
        setFilters({ ...filters, ['textSearch']: e.target.value })
      }, 500)
    }
    if (e.target.value == '') {
      setFilters({ ...filters, ['textSearch']: e.target.value })
    }
  }
  const handleAddPracticeLocationClick = () => {
    history.push('/admin/AddPracticeLocations');
  };
  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setFilters({
      ...filters,
      city: event.target.value
    })
  };
  return (
    <>
      <div>
        <div className="row">
          <div className="col-4 md-5">
            <h2 className="d-inline-block">Practice Locations</h2>
          </div>
          <div className="col-8 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <ServerSideSearch
              placeholder="Search Location"
              onChange={handleSearch}
              value={selected.textSearch}
            />
            <FormControl>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCity}
                label="City"
                onChange={handleCityChange}
                MenuProps={{ PaperProps: { style: { maxHeight: 160 } } }}
                style={{ maxHeight: 40, borderRadius: "2px", width: "220px" }}
              >
                {allCities === "isLoading" && (
                  <MenuItem disabled>
                    Loading...
                  </MenuItem>
                )}
                {allCities !== "isLoading" && allCities.length === 0 && (
                  <MenuItem disabled>
                    No data
                  </MenuItem>
                )}

                {allCities !== "isLoading" && allCities.length !== 0 && allCities[0].cities
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(city => (
                    <MenuItem key={city.id} value={city.id}>
                      {city.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
            {permissions.includes('CanAddPracticeLocation') && (
              <AddButton onClick={handleAddPracticeLocationClick} text="+ Add Location" />
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Location Type</b>{" "}
                  </td>
                  {/* <td>
                    {" "}
                    <b className="heading_role">City</b>{" "}
                  </td> */}
                  <td>
                    {" "}
                    <b className="heading_role">Address</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {practiceLocations === 'isLoading' && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td
                      colSpan={4}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {practiceLocations &&
                  practiceLocations.length == 0 && (
                    <tr className="text-center" style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}>
                      <td colSpan={4}>No data found</td>
                    </tr>
                  )}
                {practiceLocations !== 'isLoading' &&
                  practiceLocations.length !== 0 &&
                  practiceLocations[0].practiceLocations
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((row, i) => (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}
                      >
                        <td style={{verticalAlign:"middle"}}>{row.name}</td>
                        <td style={{verticalAlign:"middle"}}>{row.typeName ? row.typeName : "Not Availabe"}</td>
                        {/* <td>{row.cityName ? row.cityName : "Not Available"}</td> */}
                        <td
                        style={{
                          whiteSpace: "pre-line",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                          verticalAlign: "middle",
                        }}
                      >
                        <div
                          style={{
                            maxHeight:
                              isExpanded || row.address.length <= 100
                                ? "none"
                                : "3.4em",
                            overflow: "hidden",
                            position: "relative",
                            width: "70%",
                          }}
                        >
                          {row.address}
                          {row.address.length > 100 && (
                            <span
                              // className="toggle_expand"
                              style={{position:"absolute", right:"0px", bottom:"0", cursor:"pointer", fontSize:"20px"}}
                              onClick={toggleExpansion}
                            >
                              ...
                            </span>
                          )}
                        </div>
                      </td>

                        <td style={{verticalAlign:"middle"}}>
                          {permissions.includes('CanUpdatePracticeLocation') && (
                            <Tooltip title="Edit Location" arrow enterDelay={500} placement="bottom">
                              <Link
                                to={{
                                  pathname: '/admin/UpdatePracticeLocations',
                                  practiceLocation: { row },
                                  id: row.id,
                                }}
                              >
                                <IconButton>
                                  <EditOutlinedIcon 
                                  />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        {practiceLocations !== 'isLoading' && practiceLocations.length !== 0 && (
          <div className="task-pagination-wrapper">
            <Pagination
              total={
                practiceLocations[0].page != undefined &&
                practiceLocations[0].page.totalPages *
                practiceLocations[0].page.pageSize
              }
              current={
                practiceLocations[0].page != undefined &&
                practiceLocations[0].page.pageNumber
              }
              pageSize={
                practiceLocations[0].page != undefined &&
                practiceLocations[0].page.pageSize
              }
              onChange={(current) => setPage(current)}
              showLessItems
            />
          </div>
        )}
      </div>
    </>
  )
}

export default PracticeLocations
