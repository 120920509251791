import React, { useState, useEffect } from "react";
import "./ForgotPassword.css";
import passwordIcon from "../../assets/images/pass_icon.png";
import { Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../assets/images/logo_text.svg";
import { useHistory, useLocation } from "react-router-dom";
import userService from "../../services/user.service";
import { toast } from "react-toastify";
import Loader from "../subComponents/Loader";
import OtpInput from "react-otp-input";

function UpdatePassword() {
  const history = useHistory();
  const initialValues = { otp: "", newPassword: "", confirmPassword: "" };
  const location = useLocation();
  const [email, setEmail] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValues] = useState(initialValues);
  const [newPasswordType, setNewPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const [submitted, setSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (location.state && location.state.email && location.state.otpGenerated) {
      setEmail(location.state.email.trim());
    } else {
      history.push("/");
    }
  }, [location.state, history]);

  const handleChange = (e) => {
    if (e && e.target) {
      const { name, value } = e.target;
      setFormValues({ ...formValues, [name]: value });
    } else {
      setFormValues({ ...formValues, otp: e });
    }

  };
  // useEffect(() => {
  //   validate(formValues);
  //   setFormErrors({});
  // }, [formValues]);

  const toggleConfirmPassword = () => {
    setConfirmPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };
  const toggleNewPassword = () => {
    setNewPasswordType((prevType) =>
      prevType === "password" ? "text" : "password"
    );
  };

  const validate = (values) => {
    const errors = {};
    const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!values.otp) {
      errors.otp = "OTP is required!";
    }
    if (values.otp.length > 0 && values.otp.length !== 6) {
      errors.otp = "OTP must be 6 digits!";
    }
    if (!values.newPassword) {
      errors.newPassword = "New password is required!";
    } else if (!passwordRegex.test(values.newPassword)) {
      errors.newPassword = "Password must include: length greater than 8 characters, at least 1 uppercase, 1 numeric value and 1 special character";
    }
    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is required!";
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = "Passwords do not match!";
    }
    return errors;
  };

  const submitHandle = async (e) => {
    e.preventDefault();
    setSubmitted(true);
    const isValid = validate(formValues);
    if (Object.keys(isValid).length === 0) {
      setIsLoading(true);
      const body = {
        confirmationCode: formValues.otp,
        newPassword: formValues.newPassword,
        email: email.trim(),
      };
      try {
        const response = await userService.verifyOtp(body);
        if (response.data.status === true) {
          toast.success("Password updated successfully!");
          setTimeout(() => {
            history.push("/");
          }, 2000);
        } else if (response.data.status === false) {
          toast.error(response.data.message);
        }
      } catch (error) {
        console.error("Error saving new password:", error);
        toast.error(response.data.message);
      } finally {
        setIsLoading(false);
      }
    } else {
      setFormErrors(isValid);
    }
  };
  const handleResendEmail = async () => {
    try {
      const response = await userService.generateOtp(email.trim());
      if (response.data.status === true) {
        toast.success("New OTP generated successfully!");
      } else {
        toast.error("Email does not exist!");
      }
    } catch (error) {
      toast.error("An error occurred while resending the email. Please try again.");
      console.error("Error resending email:", error);
    }
  };


  return (
    <>
      <ToastContainer />
      <div className="container-fluid p-0">
        <div className="row m-0">
          <div className="col-6">
            <div className="logo-section">
              <div className="border-div">
                <img style={{ height: "90%" }} src={logo} alt="" />
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="forgotPassword-form">
              <div className="form">
                <div>
                  <h2>Create New Password</h2>
                  <p>Ensure your new password is unique and strong.</p>
                  <p>Please enter the 6-digit OTP sent to <span style={{
                    fontFamily: "Roboto",
                    fontSize: "16px",
                    fontWeight: "600",
                    lineHeight: "19px",
                    textAlign: "left",
                    color: "#000000"
                  }}>{email}</span></p>
                </div>
                <form onSubmit={submitHandle}>
                  <div className="mt-5">
                    <label htmlFor="#newPassword">
                      <img src={passwordIcon} alt="" />
                      OTP
                    </label>
                    <OtpInput
                      value={formValues.otp}
                      name="otp"
                      id="otp"
                      onChange={handleChange}
                      numInputs={6}
                      separator={<span>-</span>}
                      inputStyle={{
                        width: "40px",
                        height: "40px",
                        marginRight: "10px",
                        marginLeft: "10px",
                        fontSize: "24px",
                        textAlign: "center",
                        padding: "5px",
                      }}
                      renderInput={(props) => <input {...props} />}
                    />

                    {
                      <p
                        style={{
                          color: "red",
                          marginTop: "20px",
                          marginLeft: "10px",
                        }}
                      >
                        {formErrors.otp}
                      </p>
                    }
                  </div>
                  <div className="input-wrapper mt-5">
                    <label htmlFor="#newPassword">
                      <img src={passwordIcon} alt="" />
                      New Password
                    </label>
                    <div className="password-input-wrapper">
                      <input
                        name="newPassword"
                        id="newPassword"
                        type={newPasswordType}
                        value={formValues.newPassword}
                        onChange={handleChange}
                      />
                      <span onClick={toggleNewPassword}>
                        {newPasswordType === "password" ? (
                          <i className="bi bi-eye-slash"></i>
                        ) : (
                          <i className="bi bi-eye"></i>
                        )}
                      </span>
                    </div>
                    {submitted && formErrors.newPassword && (
                      <p style={{ color: "red" }}>{formErrors.newPassword}</p>
                    )}
                  </div>

                  <div className="input-wrapper">
                    <label htmlFor="confirmPassword">
                      <img src={passwordIcon} alt="" />
                      Confirm Password
                    </label>
                    <div className="password-input-wrapper">
                      <input
                        name="confirmPassword"
                        id="confirmPassword"
                        type={confirmPasswordType}
                        value={formValues.confirmPassword}
                        onChange={handleChange}
                      />
                      <span onClick={toggleConfirmPassword}>
                        {confirmPasswordType === "password" ? (
                          <i className="bi bi-eye-slash"></i>
                        ) : (
                          <i className="bi bi-eye"></i>
                        )}
                      </span>
                    </div>
                    {submitted && formErrors.confirmPassword && (
                      <p style={{ color: "red" }}>
                        {formErrors.confirmPassword}
                      </p>
                    )}
                  </div>

                  <div className="input-wrapper mt-5">
                    <button type="submit">
                      {isLoading && (
                        <div className="mt-2">
                          <Loader />
                        </div>
                      )}
                      Submit
                    </button>
                  </div>
                </form>
                <div style={{
                  marginTop: "10px", width: "380px", fontFamily: "Roboto",
                  fontSize: "16px",
                  fontStyle: "italic",
                  fontWeight: "400",
                  lineHeight: "24px",
                  textAlign: "center",
                  color: "#000000"
                }}>
                  <p>
                    If you haven’t received an email in 5 minutes, check your spam folder,{" "}
                    <a
                      style={{ color: "#0047FF", textDecoration: "underline", cursor: "pointer" }}
                      onClick={handleResendEmail}
                    >
                      resend OTP
                    </a>, or{" "}
                    <a
                      href="/forgotPassword"
                      style={{ color: "#0047FF", textDecoration: "underline", cursor: "pointer" }}
                    >
                      try again
                    </a>.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row m-0">
          <div className="col-12 p-0">
            <div className="footer">
              <p className="text-center">&#9400; Copyright Field Force 2024</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default UpdatePassword;
