import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { getTaskPriorities } from "../../actions/userActions/taskPriorityAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import { IconButton,Tooltip } from "@mui/material";
import Pagination from '../paginatioin/pagination';
import EditButton from "../subComponents/buttons/EditButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

function TaskPriorities() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(10)

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(getTaskPriorities());
  }, []);
  const taskPriorities = useSelector(
    (state) => state.task_priorities_reducer.taskPriorities
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [query, setQuery] = useState("");
  const filter_taskPriorities =
    taskPriorities !== "isLoading"
      ? taskPriorities.filter((taskPriority) => {
        return taskPriority.name.toLowerCase().includes(query.toLowerCase());
      })
      : [];
  const handleAddTaskPriorityClick = () => {
    history.push('/admin/addTaskPriorities');
  };
  // Pagination logic
  const indexOfLastPriority = currentPage * perPage;
  const indexOfFirstPriority = indexOfLastPriority - perPage;
  const currentPriorities = filter_taskPriorities.slice(indexOfFirstPriority, indexOfLastPriority);
  const totalPages = Math.ceil(filter_taskPriorities.length / perPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 md-5">
            <h2 className="d-inline-block">Task Priorities</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <Search
              setText={setQuery}
              text={query}
              placeholder="Search Priority"
            />
            {permissions.includes("CanAddTaskPriority") && (
              <AddButton onClick={handleAddTaskPriorityClick} text="+ Add Priority" />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Color</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Status</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {taskPriorities === "isLoading" && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td
                      colSpan={5}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {taskPriorities !== "isLoading" &&
                  currentPriorities.length === 0 && (
                    <tr className="text-center" style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}>
                      <td colSpan={5}>No data found</td>
                    </tr>
                  )}

                {taskPriorities !== "isLoading" &&
                  currentPriorities
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((row, i) => (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}
                      >
                        <td style={{verticalAlign:"middle"}}>{row.name}</td>
                        <td
                          style={{
                            whiteSpace: "pre-line",
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                            verticalAlign: "middle",
                          }}
                        >
                          <div
                            style={{
                              maxHeight:
                                isExpanded || row.description.length <= 100
                                  ? "none"
                                  : "3.4em",
                              overflow: "hidden",
                              position: "relative",
                              width: "67%",
                            }}
                          >
                            {row.description}
                            {row.description.length > 100 && (
                              <span
                                // className="toggle_expand"
                                style={{ position: "absolute", right: "0px", bottom: "0", cursor: "pointer", fontSize: "20px" }}
                                onClick={toggleExpansion}
                              >
                                ...
                              </span>
                            )}
                          </div>
                        </td>
                        <td style={{verticalAlign:"middle"}}>
                          <div
                            style={{
                              height: "20px",
                              width: "20px",
                              backgroundColor: row.color,
                              display: "inline-block",
                            }}
                          ></div>
                          {/* <div className="float-left mr-2">
                          <span style={{ lineHeight: "20px" }}>
                            {row.color}
                          </span>
                        </div> */}
                        </td>
                        <td style={{verticalAlign:"middle"}}>
                          <span
                            style={{
                              color: row.active
                                ? "#2196F3"
                                : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                              borderRadius: "100px",
                              border: `1px solid ${row.active
                                ? "var(--primary-main, #2196F3)"
                                : "var(--chip-defaultEnabledBorder, #BDBDBD)"
                                }`,
                              padding: "3px 10px",
                            }}
                          >
                            {row.active !== undefined ? (row.active ? "Active" : "In-active") : "Active"}
                          </span>

                        </td>

                        <td style={{verticalAlign:"middle"}}>
                          {permissions.includes("CanUpdateTaskPriority") && (
                            <Tooltip title="Edit Priority" arrow enterDelay={500} placement="bottom">
                              <Link
                                to={{
                                  pathname: "/admin/UpdateTaskPriorities",
                                  taskPriorities: { row },
                                  id: row.id,
                                }}
                              >
                                 <IconButton>
                                  <EditOutlinedIcon 
                                  />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='task-pagination-wrapper'>
          <Pagination
            total={totalPages}
            current={currentPage}
            pageSize={1}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default TaskPriorities;
