import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDoctorSpecializations } from "../../actions/userActions/doctorSpecializationAction";
import { Link, useHistory } from "react-router-dom";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import { IconButton,Tooltip } from "@mui/material";
import Pagination from '../paginatioin/pagination';
import EditButton from "../subComponents/buttons/EditButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';


function DoctorSpecializations() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [expanded, setExpanded] = useState({});
  const [currentPage, setCurrentPage] = useState(1)
  const [perPage] = useState(10)

  const toggleExpansion = (id) => {
    setExpanded((prev) => ({
      ...prev,
      [id]: !prev[id],
    }));
  };

  useEffect(() => {
    dispatch(getDoctorSpecializations());
  }, [dispatch]);

  const specializations = useSelector(
    (state) => state.doctor_specialization_reducer.specializations
  );
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
  const [query, setQuery] = useState("");
  const filter_specializations =
    specializations !== "isLoading"
      ? specializations.filter((specialization) => {
        return specialization.name
          .toLowerCase()
          .includes(query.toLowerCase());
      })
      : [];

  const handleAddDoctorSpecializationClick = () => {
    history.push('/admin/AddDoctorSpecializations');
  };
  // Pagination logic
  const indexOfLastSpecialization = currentPage * perPage;
  const indexOfFirstSpecialization = indexOfLastSpecialization - perPage;
  const currentSpecializations = filter_specializations.slice(indexOfFirstSpecialization, indexOfLastSpecialization);
  const totalPages = Math.ceil(filter_specializations.length / perPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 md-5">
            <h2 className="d-inline-block">Doctor Specializations</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <Search
              setText={setQuery}
              text={query}
              placeholder="Search Specialization"
            />
            {permissions.includes("CanAddDoctorSpecialization") && (
              <AddButton onClick={handleAddDoctorSpecializationClick} text="+ Add Specialization" />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {specializations === "isLoading" && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td colSpan={3}>
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {specializations !== "isLoading" &&
                  currentSpecializations.length === 0 && (
                    <tr className="text-center" style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}>
                      <td colSpan={3}>No data found</td>
                    </tr>
                  )}
                {filter_specializations !== "isLoading" &&
                  currentSpecializations
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((row, i) => (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}
                      >
                        <td style={{verticalAlign:"middle"}}>{row.name}</td>
                        <td
                          style={{
                            whiteSpace: "pre-line",
                            wordBreak: "break-word",
                            overflowWrap: "break-word",
                            verticalAlign: "middle",
                          }}
                        >
                          <div
                            style={{
                              maxHeight:
                                expanded[row.id] || row.description.length <= 155
                                  ? "none"
                                  : "3.4em",
                              overflow: "hidden",
                              position: "relative",
                              width: "71%",
                            }}
                          >
                            {row.description}
                            {row.description.length > 155 && (
                              <span
                                className="toggle_expand"
                                onClick={() => toggleExpansion(row.id)}
                              >
                                {expanded[row.id] ? '...' : '...'}
                              </span>
                            )}
                          </div>
                        </td>
                        <td style={{verticalAlign:"middle"}}>
                          {permissions.includes('CanUpdateDoctorSpecialization') && (
                            <Tooltip title="Edit Specialization" arrow enterDelay={500} placement="bottom">
                              <Link
                                to={{
                                  pathname: "/admin/UpdateDoctorSpecializations",
                                  doctorSpecialization: { row },
                                  id: row.id,
                                }}
                              >
                               <IconButton>
                                  <EditOutlinedIcon 
                                  />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='task-pagination-wrapper'>
          <Pagination
            total={totalPages}
            current={currentPage}
            pageSize={1}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default DoctorSpecializations;
