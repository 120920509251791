import React from 'react'
import '../../subComponents/tableActions/TableAction.css'
import EyeIcon from '../../../assets/images/eye_icon.svg'
import EditIcon from '../../../assets/images/edit_icon.svg'
import DeleteIcon from '../../../assets/images/delete_icon.svg'

function TableAction(props) {
    return (
        <>
        <div className="d-flex justify-content-center">
            <img className='mr-2' onClick={() => props.onEyeClick()} src={EyeIcon} alt="" />
            <img className='mr-2' onClick={() => props.onEditClick()} src={EditIcon} alt="" />
            <img className='mr-2' onClick={() => props.onDeleteClick()} src={DeleteIcon} alt="" />
        </div>
        </>
    )
}
export default TableAction
