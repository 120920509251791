import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getEmploymentStatuses } from "../../actions/userActions/employmentStatusAction";
import Loader from "../subComponents/Loader";
import Search from "../subComponents/search/Search";
import AddButton from "../subComponents/buttons/AddButton";
import { IconButton,Tooltip } from "@mui/material";
import Pagination from '../paginatioin/pagination';
import EditButton from "../subComponents/buttons/EditButton";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function EmploymentStatuses() {
  const query = useQuery();
  const [isExpanded, setIsExpanded] = useState(false);
  const initialPageNumber = parseInt(query.get("page")) || 1;
  const [currentPageNumber, setCurrentPageNumber] = useState(initialPageNumber);
  const [searchQuery, setSearchQuery] = useState(query.get("search") || "");
  const [previousQuery, setPreviousQuery] = useState("");

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };
  const dispatch = useDispatch();
  const history = useHistory();
 
  const pageSize = 10;

  useEffect(() => {
    const pageNumber = currentPageNumber;

    if (searchQuery !== previousQuery) {
      setCurrentPageNumber(1);
    }
    dispatch(getEmploymentStatuses(pageNumber, pageSize, searchQuery));
    setPreviousQuery(searchQuery);
  }, [currentPageNumber, searchQuery]);

  const employmentStatuses = useSelector(
    (state) => state.employmentStatuses_reducer.employmentStatuses
  );
  const totalPages = employmentStatuses.totalPages;
  const permissions = JSON.parse(localStorage.getItem("permissionsList"));
 
  const handleAddEmployeementStatusClick = () => {
    history.push('/admin/AddEmploymentStatuses');
  };

 const handlePageChange = (page) => {
    setCurrentPageNumber(page);
    history.push(`?page=${page}&search=${searchQuery}`);
  };

  const handleSearchChange = (query) => {
    setSearchQuery(query);
    setCurrentPageNumber(1);
    history.push(`?page=1&search=${query}`);
  };
  return (
    <>
      <div>
        <div className="row">
          <div className="col-6 md-5">
            <h2 className="d-inline-block">Employment Status</h2>
          </div>
          <div className="col-6 md-4 d-flex justify-content-end" style={{ gap: "16px" }}>
            <Search
              setText={handleSearchChange}
              text={searchQuery}
              placeholder="Search Status"
            />
            {permissions.includes("CanAddEmploymentStatus") && (
              <AddButton onClick={handleAddEmployeementStatusClick} text="+ Add Status" />
            )}
          </div>
        </div>

        <div className="row">
          <div className="col-md-12">
            <table className="table table-striped  mt-4">
              <thead className="text-left" style={{ backgroundColor: "#f5f9fc" }}>
                <tr>
                  <td>
                    {" "}
                    <b className="heading_role">Name</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Description</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Status</b>{" "}
                  </td>
                  <td>
                    {" "}
                    <b className="heading_role">Action</b>{" "}
                  </td>
                </tr>
              </thead>
              <tbody className="text-left">
                {employmentStatuses === "isLoading" && (
                  <tr style={{
                    backgroundColor: "transparent",
                    "--x-table-accent-bg": "transparent",
                  }}>
                    <td
                      colSpan={4}
                    >
                      <Loader colored={true} />
                    </td>
                  </tr>
                )}
                {employmentStatuses && employmentStatuses.result && employmentStatuses.result.length ===0 && employmentStatuses !== "isLoading" &&
                  employmentStatuses.result.length === 0 && (
                    <tr className="text-center" style={{
                      backgroundColor: "transparent",
                      "--x-table-accent-bg": "transparent",
                    }}>
                      <td colSpan={4}>No data found</td>
                    </tr>
                  )}

                {employmentStatuses && employmentStatuses.result && employmentStatuses.result.length !==0 && employmentStatuses !== "isLoading" && 
                  employmentStatuses.result
                    .slice()
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((row, i) => (
                      <tr
                        key={i}
                        style={{
                          backgroundColor: "transparent",
                          "--x-table-accent-bg": "transparent",
                        }}
                      >
                        <td style={{verticalAlign:"middle"}}>{row.name}</td>
                        <td
                        style={{
                          whiteSpace: "pre-line",
                          wordBreak: "break-word",
                          overflowWrap: "break-word",
                          verticalAlign: "middle",
                        }}
                      >
                        <div
                          style={{
                            maxHeight:
                              isExpanded || row.description.length <= 100
                                ? "none"
                                : "3.4em",
                            overflow: "hidden",
                            position: "relative",
                            width: "67%",
                          }}
                        >
                          {row.description}
                          {row.description.length > 100 && (
                            <span
                              // className="toggle_expand"
                              style={{position:"absolute", right:"0px", bottom:"0", cursor:"pointer", fontSize:"20px"}}
                              onClick={toggleExpansion}
                            >
                              ...
                            </span>
                          )}
                        </div>
                      </td>
                        <td style={{verticalAlign:"middle"}}>
                          <span
                            style={{
                              color: row.active
                                ? "#2196F3"
                                : "var(--text-primary, rgba(0, 0, 0, 0.87))",
                              borderRadius: "100px",
                              border: `1px solid ${row.active
                                ? "var(--primary-main, #2196F3)"
                                : "var(--chip-defaultEnabledBorder, #BDBDBD)"
                                }`,
                              padding: "3px 10px",
                            }}
                          >
                            {row.active !== undefined ? (row.active ? "Active" : "In-active") : "Active"}
                          </span>
                        </td>

                        <td style={{verticalAlign:"middle"}}>
                          {permissions.includes("CanUpdateEmploymentStatus") && (
                            <Tooltip title="Edit Status" arrow enterDelay={500} placement="bottom">
                              <Link
                                to={{
                                  pathname: "/admin/UpdateEmploymentStatuses",
                                  employmentStatus: { row },
                                  id: row.id,
                                }}
                              >
                             <IconButton>
                                  <EditOutlinedIcon 
                                  />
                                </IconButton>
                              </Link>
                            </Tooltip>
                          )}
                        </td>
                      </tr>
                    ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className='task-pagination-wrapper'>
          <Pagination
            total={totalPages}
            current={currentPageNumber}
            pageSize={1}
            onChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
}

export default EmploymentStatuses;
