import {
    PRACTICE_LOCATION_SUCCESS,
    PRACTICE_LOCATION_FAIL,
    PRACTICE_LOCATION_PENDING,
    PRACTICE_LOCATION_TYPE_PENDING,
    PRACTICE_LOCATION_TYPE_SUCCESS,
    PRACTICE_LOCATION_TYPE_FAIL,
    ADD_PRACTICE_LOCATION_PENDING,
    ADD_PRACTICE_LOCATION_SUCCESS,
    ADD_PRACTICE_LOCATION_FAIL,
    UPDATE_PRACTICE_LOCATION_PENDING,
    UPDATE_PRACTICE_LOCATION_SUCCESS,
    UPDATE_PRACTICE_LOCATION_FAIL,
} from "../../actions/types";

const initialState = { 
    practiceLocations: [],
    practiceLocationTypes : [],
    addPracticeLocation : '',
    updatePracticeLocation : ''
    };

const practiceLocations_reducer = (state = initialState, action) => {
    const { type, payload } = action;
    switch (type) {
        case PRACTICE_LOCATION_SUCCESS:
            return {
                ...state,
                practiceLocations: payload.practiceLocations,
            };
        case PRACTICE_LOCATION_PENDING:
            return {
                ...state,
                practiceLocations: payload.practiceLocations,
            };
        case PRACTICE_LOCATION_FAIL:
            return {
                ...state,
                practiceLocations: []
            };
        case PRACTICE_LOCATION_TYPE_SUCCESS:
            return {
                ...state,
                practiceLocationTypes: payload.practiceLocationTypes,
            };
        case PRACTICE_LOCATION_TYPE_PENDING:
            return {
                ...state,
                practiceLocationTypes: payload.practiceLocationTypes,
            };
        case PRACTICE_LOCATION_TYPE_FAIL:
            return {
                ...state,
                practiceLocationTypes: []
            };
            case ADD_PRACTICE_LOCATION_SUCCESS:
            return {
                ...state,
                addPracticeLocation: payload.addPracticeLocation,
            };
        case ADD_PRACTICE_LOCATION_PENDING:
            return {
                ...state,
                addPracticeLocation: payload.addPracticeLocation,
            };
        case ADD_PRACTICE_LOCATION_FAIL:
            return {
                ...state,
                addPracticeLocation: false
            };
        case UPDATE_PRACTICE_LOCATION_SUCCESS:
            return {
                ...state,
                updatePracticeLocation: payload.updatePracticeLocation,
            };
        case UPDATE_PRACTICE_LOCATION_PENDING:
            return {
                ...state,
                updatePracticeLocation: payload.updatePracticeLocation,
            };
        case UPDATE_PRACTICE_LOCATION_FAIL:
            return {
                ...state,
                updatePracticeLocation: false
            };
        default:
            return state;
    }
}
export default practiceLocations_reducer